@keyframes fadeIn {
    0% {
        opacity: 0;
        border-radius: 0;
    }

    100% {
        opacity: 1;
        border-radius: 50px;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        border-radius: 0;
    }

    100% {
        opacity: 1;
        border-radius: 50px;
    }
}

div#SqCo {
    text-align: center;
    width: 70%;
}


div#SqCo p {
    color: #616e80;
    font-size: 26px;
}

div#SqCo p .point {
    opacity: 0;
}

div#SqCo p .point.one {
    -webkit-animation: fadeIn 1s linear 0s infinite alternate;
    animation: fadeIn 1s linear 0s infinite alternate;
}

div#SqCo p .point.two {
    -webkit-animation: fadeIn 1s linear 0.5s infinite alternate;
    animation: fadeIn 1s linear 0.5s infinite alternate;
}

div#SqCo p .point.three {
    -webkit-animation: fadeIn 1s linear 1s infinite alternate;
    animation: fadeIn 1s linear 1s infinite alternate;
}
