.google,
.fb {
  width: 10%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  /* font-size: 17px; */
  font-size: 1rem;
  line-height: 20px;
  text-decoration: none;
  color: white;
  text-align: center;
}

.fb {
  background-color: #3b5998;
  text-align: center;
}

.google {
  background-color: #dd4b39;
}

.flexCont {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.petLogo {
  max-width: 100%;
  height:100%;
  border:"10px solid red;"
}
.logoContainer{
  height:20%;
}

.mainHeading {
  font-family: 'Roboto',sans-serif;
  /* font-size: 27px; */
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.22;
  /* color: #094fa3; */
  color: #3295F7;
}

.card-comp {
  border: none;
  border-radius: 8px;
  width: 70% !important;
  /* padding: 1%; */
}

.loginBtn {
  width: 30%;
  margin: auto;
  /* height: 6vh; */
  border-radius: 8px;
  /* font-size: 30px; */
  /* margin-left: 8%; */
}

.resetPassword {
  border-radius: 8px;
  background-color: #BDBDBD;
  border-style: none;
}

.confirmPassword {
  border-radius: 8px;
  background-color: #5E5E5E;
  border-style: none;
  color: #FFFFFF;
}

/* button.otp-back-btn{
  border-style: none;
  border-radius: "8px";
  box-shadow: "2px 2px 5px -2px #000000";
  background-color: "white" !important;
  color: "#121212";
  font-family: "Poppins, sans-serif";
  font-weight: "500";
  font-size: "1rem";
  padding: "7px";
  width: "150px";
} */

/* button.otp-back-btn{
  border-style: none;
  border-radius: "8px";
  box-shadow: "2px 2px 5px -2px #000000";
  background-color: "white" !important;
  color: "#121212";
  font-family: "Poppins, sans-serif";
  font-weight: "500";
  font-size: "1rem";
  padding: "7px";
  width: "150px";
} */

.smBtn {
  width: 100%;
  border-radius: 8px;
  border: 1px solid black;
  background-color: white;
  color: black;
  font-size: 1rem;
  padding:5px
}
.smBtn:hover {
  transform: scale(1.2);
}


.smImg {
  /* padding-right: 2px; */
  margin-right: 15px;
  width: 15px;
  height: 15px;
 
  

}

.loginBtnRegister {
  width: 30%;
  /* height: 6vh; */
  border-radius: 8px;
}

.loginBtnVerify {
  width: 20%;
  /* height: 6vh; */
  background-color: white !important;
  border-color: #3295F7 !important;
  border-radius: 8px;
  color: #3295F7 !important;
}

.otpresend {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3295F7;
  background-color: white !important;
  border: none;
  text-align: left;
}

.otpresend:hover {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3295F7 !important;
  background-color: white !important;
  border: none !important;
}

.otpresend:active {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3295F7;
  background-color: white !important;
  border: none !important;
}

.otpresend:focus {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3295F7 !important;
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
}

.otpresend:disabled{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #a8a8a8 !important;
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
}

.register {
  font-family: 'Roboto',sans-serif;
  /* font-size: 14px; */
  font-size: 1rem;
  line-height: 1.36;
  text-align: left;
  color: #5d8ffc;
  padding-left: 4% !important;
}

.forgotpass {
  font-family: 'Roboto',sans-serif;
  /* font-size: 14px; */
  font-size: 0.8rem;
  line-height: 1.36;
  /* text-align: right; */
  color: #929699;
  /* margin-right: 23% !important; */
}

.googlefblink {
  /* color: white !important; */
  color: black;
  font-size: 0.8rem;
  text-decoration: none !important;
}

/* .googlefblink:hover {
  color: white !important;
  color: white;
  text-decoration: none !important;
} */

.socialBtn {
  width: 60%;
  border: none !important;
  border-radius: 10px !important;
}

.privacyLink {
  font-family: 'Inter',sans-serif;
  /* font-size: 13px; */
  font-size: 0.8rem;
  line-height: 1.54;
  letter-spacing: 0.03px;
  text-align: center;
  /* color: #ffffff; */
  color: #000000;
  /* text-decoration: none !important; */
}

/* .privacyLink:hover {
  color: white !important;
} */

.loginForm {
  margin-top: 10%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.loginCheckbox {
  padding-left: 22% !important;
  margin-top: 5%;
}

.input[type='email'] {
  background-color: white !important;
}

.carouselImg {
  border: none !important;
  height: 100vh !important;
  width: 100%;
}

.fpBtn1 {
  width: 55% !important;
  margin-left: 29% !important;
}

.fpBtn2 {
  width: 55% !important;
  margin-right: 29% !important;
}

@media only screen and (max-width: 414px) {
  .flexRow {
    margin-left: 0.3% !important;
  }

  .flexCont {
    padding-bottom: 1% !important;
  }
}

@media only screen and (max-width: 767px) {
  .fpBtn {
    margin-bottom: 5% !important;
  }

  .navbar-toggler-icon {
    color: white !important;
  }

  .navbar-toggler {
    color: white !important;
  }

  .loginline {
    line-height: 1.42 !important;
  }

  .loginBtn {
    margin-bottom: 7% !important;
  }

  .signinLink {
    margin-left: 8% !important;
    font-size: 0.7rem !important;
  }

  .signupBtn {
    width: 100% !important;
  }

  .forgotpass {
    margin-left: 5% !important;
    float: left !important;
  }
}

.hr-lines {
  position: relative;
  max-width: 500px;
  margin: 8px auto;
  text-align: center;
  color:black;
  font-size: 15px;
}

/* .hr-lines:before{
  content:" ";
  display: block;
  height: 2px;
  width: 130px;
  position: absolute;
  top: 50%;
  left: 0;
  background: red;
} */

/* .hr-lines:after{
  content:" ";
  height: 2px;
  width: 130px;
  background: red;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
} */