.modalshr {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    
  }

  .modalshr-content{
    position: relative;
    /* top: -10%; */
    height: 85%;
    width: 50%;
    /* bordeR:2px solid red; */
  }
  .modalshr-content-data{
    position: relative;
    /* top: -10%; */
    height: 90%;
    width: 50%;
    /* bordeR:2px solid green; */
  }
  
  .modalshr.open {
    display: block;
  }

  .headingContainer {
    display: flex;
    justify-content: space-between;
 
  }

  .MainheadingShr{
    color:rgb(61, 131, 250);
    margin-top: 9px;
  }

  .closeshr-btn img{
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    height: 25px
  }
  
  .closeshr-btn:hover,
  .closeshr-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

 .search-button{
    display: flex;
 }

 .content-library-content{
    margin-top: 5%;
    height: 75%;
    overflow-y: scroll;
    overflow-x: hidden;
    /* border:2px solid gold; */
 }
 .content-library-content-data{
    margin-top: 5%;
    height: 68%;
    overflow-y: scroll;
    overflow-x: hidden;
    /* border:2px solid gold; */
 }

 .org-details-header{
  display: flex;
 }

 .org-details-form{
    margin-top: 5%;
 }
 
 .org-detail-row{
    margin-top: 2%;
 }
 .org-labels{
    font-weight: 600;
    display: flex;
    align-items: center;
 }

 .performance-metrics{
  margin-top: 5%;
 }

 .performance-metrics-data-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%
 }

 .performance-metrics-data{
  width: 98%;
 }

 .clauses{
  margin-top: 5%;
 }

 .far-clauses{
  margin-top:2%
 }

 .far-clause-content{
  height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid silver;
  border-radius: 5px;
  margin-top: 2%;
 }

 .final-buttons{
  display: flex;
  justify-content: center;
  margin: 4%;
 }

 .sub-heading{
  display: flex;
 }

 .sub-heading-clauses{
  display: flex;
  justify-content: space-between;
 }

 .sub-heading-title{
  margin-top: 10px;
 }

 .organization-logo {
  max-width: 90px;
  max-height: 90px;
}

.full-width-table {
  width: 100%;
}
.half-width-cell {
  width: 50%;
}

.indented-list {
  margin-left: 4%;
}