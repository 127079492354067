.Gallery-container {
    background-color: white;
    height: auto;
    width: 100wh;
}





.Gallery-topBar {    height: 75px;
    width: 100%;

    display: flex;
    align-items: end;
    justify-content: space-between;

    padding: 0 20px;
    
    
}

.Gallery-input {
    position: relative;
    left: 0%;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 5px;
   
 

}

.Gallery-inputBox {
    
    width: 100%;
    padding: 8px;
    border-radius: 15px;
    border: none;
    outline: none;
    color: black;
    
    box-shadow:  0 2px 4px rgba(0, 0, 0, 0.4)

}




.Gallery-IconDiv {
    width: 20%;
    height: 40px;
    position: relative;
    right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
  
    

}

.Gallery-Icon{
    color: #1976D2
}


.Gallery-templateDiv {

    padding: 30px;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;

}
.templateCardDiv {
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap onto multiple lines */
    justify-content: flex-start; /* Align flex items to the start (left) */
    margin-top: 20px;
}

.templateCard {
   width: 250px;
   height: 250px;
    border-radius: 50px;
    margin-right: 30px;
    margin-top: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.templateHeading {
    text-align: center;
    margin-bottom: 10px;
}

.cardContent {
   border-radius: 40px;

    height: 100%;
}

.cardIcon {
    height: 40px;
    width: 100%;
    position: relative;
    top: 120px;
   
    border-top: 1px solid black; 
 
   
}

.Gallery-categary{

    margin-top: 30px;
    margin-left: 20px;
}
.backButtonDiv{
    width:100%;
    margin-top: 30px;

}

.catagoryDiv{
    margin-top: 30px;
}