.drop {
  background-color: white !important;
}

.MuiMenu-list {
  outline: 0;
  background-color: white !important;
}

.hvrIcon {
  position: absolute;
  display: none;
}

.hvrRow:hover .hvrIcon {
  display: inline-block;
  color: #1976d2;
}

.vertical-menu {
  width: 250px;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 8px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #1976d2;
  color: white;
}

/* .MuiTablePagination-select{
  height: 100px !important;
  width: 50px !important;
} */