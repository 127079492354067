/* #listCard {
    transition: background-color 3s, transform 3s;
}

#listCard:hover {
    background-color: aliceblue;
    transform: scale(1.1);
} */
.keySearch{
    width:"500px";
    border:"blue";
}
.keySearch p{
    font-size: 16px;
    font-weight: bold;
    padding-top: 10px;
    color:brown;
    
}
.keySearch input{
    font-size: 16px;
    font-weight: bold;
    color:blue;
    width:"100%";
    height:"30px";
    
}
.searchKeyList{
    width:"280px";
    background-color: lightgreen;
    padding: 5px;
    border-radius: 2px;
    display: flex;
    height:auto;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    

}

.filterBlock{
    width:100%;
    height:40px;
    display: flex;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
    border:1px solid #ccc
   
}
.selected_filterBlock{
    width:100%;
    height:50px;
    display: flex;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    border:"2px solid blue";
    background-color: #3d83fa;
    color:white;
    
}

.filterBlockp1{
    width:85%;
    display:flex;
    height:100%;
    padding-left: 10px;
    padding-top: 15px;
    font-size: 15px;
    align-items: center;
    color:black;
    font-weight: 600;
}
.filterBlockp2{
    width:8%;
    /* border:1px solid blue; */
    margin-left: 15px;
    display:flex;
    align-items: center;
    justify-content: left;
}
.addIcon{
    /* background-color: lightgray; */
    border-radius: 50%;
    padding:3px;
    border:1.5px solid black;
    color:black;
    
}
.sel_addIcon{
    background-color: white;
    border-radius: 50%;
    padding:3px;
    color:#3d83fa;
    border:2px solid white;
}
.keyword_search{
    width:95%;
    outline:none;
    height:40px;
    border-radius: 15px;
    padding-left: 10px;
    border:1px solid lightgray;
    margin-top: -10px;
}
.keySearch_title{
   height:30px;
  color:#3d83fa;
  display: flex;
  justify-content:start;
  align-items: center;
    font-weight: bold;
    font-size: 15px;
    padding-left: 2px;
   margin-left: 8px;
   /* border:1px solid red */
}

.sliding-div {
    position: absolute;
    top: 8%;
    left: -300px; /* Start position outside of view */
    /* width: 50%; */
    /* height: auto; */
    background-color: white;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  
  .sliding-div.open {
    left: 0; /* End position */
  }

