.menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .menu-item:hover {
    background-color: #A8D2FF;
    color: white; /* Optional: Change the text color to white when hovering */
  }
  