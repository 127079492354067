.whiteText{
    color: black;
}

.dark-mode  .whiteText{
    color: white;
}

/* Dark mode textarea styling */
.dark-mode .notesTextarea {
    background-color: #333 !important; /* Dark gray background */
    color: white !important;           /* White text color */
    border: 1px solid #555 !important; /* Optional: lighter gray border */
  }
  
  /* Placeholder text styling in dark mode */
  .dark-mode .notesTextarea::placeholder {
    color: rgba(255, 255, 255, 0.7);  /* Light gray for placeholder text */
  }
  
  /* Optional: Change focus outline color in dark mode */
  .dark-mode .notesTextarea:focus {
    outline: none;
    border-color: white !important;   /* White border when focused */
    box-shadow: 0 0 5px white !important; /* Optional: white glow on focus */
  }
  