.background-red {
  /* background-color: #bbdefb; */
  background-color: #ffffff;
  /* border-left: 5px solid #386cfc; */
  /* color: #386cfc; */
  color: #1976D2;
  border-start-end-radius: 1rem;
  border-end-end-radius: 1rem;
  box-shadow: 4px 8px 16px 1px #000000;
}

.background-red2 {
  /* background-color: #bbdefb; */
  background-color: #ffffff;
  /* border-left: 5px solid #386cfc; */
  /* color: #386cfc; */
  color: #1976D2;
  border-radius: 1rem;
  /* border-start-end-radius: 1rem;
  border-end-end-radius: 1rem; */
  box-shadow: 4px 8px 16px 1px #000000;
}

.background-grey {
  background-color: #ccced1 !important;
}

.iconCol {
  /* color:"black !important" */
  color: #1976d2 !important;
}

/* 
.sidenavbar-textlink {
  text-decoration: none;
  color: #ffffff;
} */

#menuList {
  padding-top: 15px !important;
}

