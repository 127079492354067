.plans h2{
    color: #43425d;
    font-size: 20px;
  }
  
/* .price-tag h1{
    font-size: 50px;
} */

.plans li{
    font-size: 14px;
    margin-left: 20px;
    margin-top: 5px;
  }
  
  .plans lh{
    font-size: 14px;
    font-weight: 600;
  }
  
  .doller{
    font-size: 30px;
  }
  
  .mon{
    margin-left: 10px;
    font-size: 25px;
    color: #5b6b82;
  }

  .price_sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
  }

  .user_count{
    background-color: transparent;
    border:0;
    margin-left: 10px;
  }

  .bg_a{
    background-image: url("../../static/mask_group_11.png");
  }

  .bg_b{
    background-image: url("../../static/mask_group_10.png");
  }

  .bg_c{
    background-image: url("../../static/mask_group_694.png");
  }

  .bg_d{
    background-image: url("../../static/mask_group_9.png");
  }


.error_bor{
  border-bottom: 2px solid red;
}

.hovp{
  box-shadow: 0 2px 2px rgba(10,16,20,.24), 0 0 2px rgba(10,16,20,.12);
  transition: box-shadow .5s ease 0s;
}

.hovp:hover{
  box-shadow: 3px 5px 3px black;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100;
}