.container-proposal {
    margin-top: 10px;

    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* Set container to flex sdisplay */
}

.LeftSec {
    background-color: white;
    height: 100%;
    /* Set height to 90% of the container's height */
    width: 75%;
    margin-right: 10px;
    /* Set width to 50% of the container's width */
    border-radius: 10px;
    padding: 15px
}

.RightSec {
    background-color: white;
    height: 100%;
    /* Set height to 90% of the container's height */
    width: 25%;
    /* Set width to 50% of the container's width */
    border-radius: 10px;

}

.proposal-topBar {
    height: 10%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
}



.proposal-title {
    width: 70%;
    height: 75%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1%;

}

.proposal-button {
    display: flex;
    justify-content: space-around;
    width: 28%;
    height: 50%;
    border-radius: 10px;


}


.proposal-section {

    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    /* Adjusted height to accommodate the remaining space */
}

.proposal-leftHide {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    height: 100%;

    width: 25%;
    margin-right: 10px;
    border-radius: 10px;
    margin-top: 10px;

}

.proposal-sectionDiv {
   margin-top :10px;
    height: 100%;
    width: 75%;
    border-radius: 10px;

}

.Accordion-Icon {
   
    width: 50%;
    position: absolute;
    top: 0;
    right: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
   
    height: 100%;
}


.accordion-Div{
    margin-bottom: 20px;
}
.proposal-editorDiv{
    margin-top: 20px;
    height:100%;
    width:100%;
}

/* .editor-button{
    background-color: violet;
    display: flex;
    justify-content: space-around;
    width: 28%;
    height: 100%;
    border-radius: 10px;
} */

/* 
.editor-button {
    background-color: violet;
    display: flex;
    justify-content: space-around;
   
    width: auto;
    height: 50%;
    border-radius: 10px;
} */

.editor-button {
   
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */

}

.proposal-layers{
    height:50px;
    width:100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .hide-accordion{
    margin-bottom: 20px;
    height: 30px;
} */



