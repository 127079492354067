.Scontainer {
    width: 78%;
    height: 110vh;
    position: absolute;
    top: 16%;
    left: 18%;
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.Sleft,
.Sright {
    width: 45%;

    background-color: white;
    border-radius: 15px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    left: 5%;

}

.Sright {
    height: 73%;
}

.Sleft {
    height: 55%;
}

/* .editor1,
.editor2 {
    height: 100%;
    width: 100%;
    resize: none;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
} */

.S-inner1 {
    height: 65%;
    margin: 6% 6% 0 6%;
    overflow: auto;
}

.S-inner2 {
    height: 55%;
    margin: 16% 6% 0 6%;
    overflow: auto;
    /* Add this to hide the overflow */
}

.S-bar {
    height: 15%;
    width: 88%;
    margin: 5% 6% 3% 6%;
    border: 1px solid rgb(222, 218, 218);
    border-radius: 5px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.S-icons {
    margin-right: 20px;
}

.Micicon {
    margin-right: 20px;
    margin-top: 10px;
}

.Uploadicon {
    margin-right: 20px;
    margin-top: 10px;
}

#S-btn {
    height: 50%;
    text-transform: none;
    font-size: 12px;
    width: 75px;
    margin-bottom: -5px;
}

.circle {
    width: 30px;
    height: 30px;
    background-color: #eaede5f0;
    color: black;
    border-radius: 50%;
    position: absolute;
    left: 50px;
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.S-leftBar {
    height: 12%;
    width: 40%;
    position: absolute;
    left: 0%;
    display: flex;
    align-items: center;
}

.btnError {
    position: absolute;
    left: 100px;
    height: 60%;
    text-transform: none;
    width: 75px;
    border-radius: 10px;
}

.lowerDiv {
    width: 45%;
    height: 20%;
    position: absolute;
    top: 53%;
    left: 5%;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    border: 0 1px 1px 1px solid white;
    background-color: white;
}

.S-Select {
    color: #333;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
}

.S-accordion {
    width: 91%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    left: 5%;
}

.AccordionSummary {
    color: #fff;
    border-bottom: 1px solid #fff;
}

.AccordionDetails {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    padding: 2px;
}

.buttonContainer {
    display: flex;
    width: 90%;
    justify-content: space-evenly;
}

.S-normalButton,
.ParaBtn {
    color: rgb(54, 50, 50);
    background-color: white;
    border: 1px solid skyblue;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.S-selectedButton {
    background-color: white;
    color: skyblue;
    border: 1px solid skyblue;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
}

.S-normalButton:hover,
.ParaBtn:hover {
    background-color: white;
    color: skyblue;
    border-color: skyblue;
}

.S-normalButton:active,
.ParaBtn:active {
    color: white;
    border: 1px solid skyblue;
}

.RightUpperDiv {
    width: 90%;
    height: 12%;
    position: absolute;
    left: 5%;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid grey;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
}

.RightUpperDiv h4,
.RightUpperDiv span,
.RightUpperDiv p {
    margin: 0;
}

.RightUpperDiv p {
    font-size: 10px;
}

.RightUpperDiv .MuiSlider-root {
    width: 40%;
}

.R-element {
    width: 60%;
    height: 35%;
    display: flex;
    align-items: center;
    resize: none;
}

.R-element h4 {
    text-decoration: underline;
}

.S-slider {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.S-slider span {
    font-size: 15px;

}

.RLowerDiv {
    height: 10%;
    width: 88%;
    margin: 1% 6% 3% 6%;
    border: 1px solid rgb(222, 218, 218);
    border-radius: 5px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.S-rightDiv {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    height: 50%;
    width: 50%;
    position: absolute;
    right: 2%;
    gap: 2px;
}

.RLowerDiv li {
    position: absolute;
    left: 5%;
}

.CountW {
    position: absolute;
    top: 74%;
    left: 6%;
}

.ChangedW {
    position: absolute;
    top: 74%;
    left: 56%;
}

.S-ul {
    padding-left: 20px;
    /* Adjust the padding as needed */

}

.R-element.active-link {
    font-weight: bold;
    text-decoration: underline;
    /* Add any other styles you want for the active link */
}


/* bullet points & para button start */
.bullet-para {
    display: inline-block;
    /* padding: 10px 10px; */
    /* padding: 10px; */
    background-color: white;
    color: #424242;
    font-weight: 300;
    text-decoration: none;
    /* border: none; */
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: border-bottom 0.3s ease;
}

.bullet-para:hover {
    /* border-bottom: 3px solid #424242; */
    color: white;
    background-color: #0069d9;
    font-weight: 500;
    border: none;
}

.bullet-para-active {
    /* display: inline-block;
    padding: 10px 10px;
    background-color: white; */
    /* padding: 10px; */
    /* border-bottom: 3px solid #424242; */
    color: white;
    background-color: #0069d9;
    font-weight: 500;
    border: none;
    /* text-decoration: none;
    border: none; */
}

/* bullet points & para button end */