.multiSelectContainer {
  min-width: 200px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 9px;
}


/* 
  .selectedRolesContainer {
    max-height: 40px; 
  
}

.selectedRole {
    margin-bottom: 5px; 
}  */

/* Modal Container */
.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

/* Modal Content */
.custom-modal-content {
  position: relative;
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

/* Close Button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.close:hover {
  color: red;
}

.manageUserTitle {
  color: #1976D2;
}

.custom-dropdown-item:hover {
  background-color: lightblue !important;
}

.cell-container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button-container1 {
  margin-left: auto; /* This pushes the button to the right */
}

