@media only screen and (max-width: 600px) {
  #searchId {
    width: 100% !important;
  }

  .backBtn {
    width: 100% !important;
  }

  .alertHead {
    margin-bottom: 3% !important;
  }

  .alertTitle {
    font-size: 15px !important;
    margin-top: 5% !important;
  }

  .alertMsg {
    font-size: 13px !important;
  }
}
