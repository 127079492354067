.AdminVisible {
  visibility: hidden;
}

.accountSettingsBtn {
  /* border: "1px solid #1976D2"; */
  border-style: none !important;
  border-radius: "8px" !important;
  box-shadow: "2px 2px 5px -2px #000000" !important;
  background-color: "#ffffff" !important;
  color: "#1976D2" !important;
  font-family: "Poppins", "sans-serif" !important;
  font-weight: "500" !important;
  font-size: "0.9rem" !important;
  padding: "7px" !important;
  width: "150px" !important;
}

/* .phoneInput {
  width: 380px !important;
} */

/* .react-tel-input input[type="tel"] {
  width: 100% !important;
} */

.password_chng:hover {
  color: blue;
}

@media only screen and (max-width: 700px) {
  .f1 {
    width: 100% !important;
  }

  .firstcol {
    padding-left: 15px !important;
  }

  /* For mobile phones: */

  #proJumbo {
    height: 250vh !important;
  }

  #proJumboC {
    height: 250vh !important;
  }

  #timezone {
    margin-top: 20% !important;
  }

  #backBtn {
    width: 100% !important;
    margin-bottom: 5%;
  }

  #pop {
    margin-bottom: 7%;
  }

  #appLabel {
    margin-top: 20%;
  }

  #mainCont {
    padding-bottom: 3% !important;
  }

  .custom-control-label {
    margin-bottom: 10% !important;
  }

  /* #timezone {
    margin-top: 0;
  } */

  .phoneInput {
    width: 30% !important;
  }
}

@media only screen and (max-width: 768px) {
  .f1 {
    width: 100% !important;
  }

  .firstcol {
    padding-left: 15px !important;
  }

  #proJumbo {
    height: 250vh !important;
  }

  #proJumboC {
    height: 250vh !important;
  }

  .profilePic {
    height: 40% !important;
    width: 30% !important;
  }

  .update {
    width: 30% !important;
    height: 20% !important;
    margin-left: 33% !important;
  }

  #timezone {
    margin-top: 0 !important;
  }

  .react-tel-input input[type="tel"] {
    width: 100% !important;
  }

  #themeSwitchLabel {
    margin-left: 1% !important;
  }
}

@media only screen and (max-width: 360px) {

  /* For mobile phones: */
  .f1 {
    width: 100% !important;
  }

  .firstcol {
    padding-left: 15px !important;
  }

  .profilePic {
    height: 40% !important;
    width: 30% !important;
  }

  .update {
    width: 40% !important;
    height: 30% !important;
    margin-left: 33% !important;
  }

  #timezone {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 500px) {
  .settigns-dp {
    width: 150px !important;
    height: 150px !important;
  }
}

.settigns-dp:hover {
  opacity: 0.6;
}

.hvrpic {
  position: relative;
  display: inline-block;
}

.hvrpic:hover .middleIcon {
  display: block;
  color: white;
}


.middleIcon {
  /* position: absolute;
   font-size: xx-large;
   margin-left: 49%;
   margin-top: -8%; */
  display: none;
  /* color: black; */
}

.w3-input {
  text-align: center;
  padding: 10px 15px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
}

.hvrInput {
  position: relative;
}

.hvrInput:hover .hvrpencil {
  display: inline-block;
  color: #1976d2;
}

.hvrpencil {
  position: absolute;
  display: none;
}

.f1 {
  width: 70%;
}

.firstcol {
  padding-left: 8%;
}

.buysub {
  margin-top: 4%;
}

.subBtn,
.subBtn:hover {
  background-image: linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6);
  color: white;
  margin-top: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

/* ****************************************** */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden
}

.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}

.hvr-underline-from-center:active:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:hover:before {
  left: 0;
  right: 0
}

/* ****************************************** */