.template_list_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 70%;
  min-height: 95vh;
  max-height: 95vh;
  min-width: 90vw;
  max-width: 90vw;

}
.CTAButtons {
  outline: none;
  font-size: 12px !important;
  border: 1px solid !important;
  margin-right: 10px !important;
  border-radius: 10px !important;
  color: #1976D2 !important;
  border-color: #1976D2 !important;
  text-transform: capitalize !important;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2) !important;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
.CTAButtons:hover {
  background-color: #1976D2 !important;
  color: white !important;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4) !important; /* Darker shadow */
}

.buttt:hover {
  background-color: #1976D2 !important;
  color: white !important;
}

.common_flex_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio_button_styling {
  padding: 12px;
  cursor: pointer;
}

.common_hader_flex_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  overflow: auto;
}

.footr_flex_button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.template_list_div {
  min-height: 70vh;

  max-height: 70vh;
 
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.default-paragraph {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.added-content {
  background-color: #3fff6c;
}

.removed-content {
  background-color: #ff7782;
}

.transition-width {
  transition: width 0.5s ease-in-out;
}

.activityBlock{
  position:fixed;
  top:40;
  right:0;
  width: 70%;
  background-color: white;
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
}
.title-card-proposal {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Four equal columns */
  gap: 5px;
  width: 100%;
  padding: 10px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
}

/* .title-card-heading,
.title-current-team,
.submission-date,
.DeadLine-date,
.created-time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.title-current-team-proposal {
  /* text-align: center; */
  width: fit-content;
  flex-direction: row;
  width: 100%;
}

.title-current-team-proposal .status {
  color: #3ded97;
}


@media screen and (max-width: 1200px) {
   
  .title-card-proposal {
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  }
  .title-card-heading-proposal,
  .title-current-team-proposal,
  .submission-date-proposal,
  .DeadLine-date-proposal,
  .created-time-proposal {
    justify-content: flex-start;
  }
  /* .title-card-heading,
  .submission-date,
  .created-time {
    justify-content: space-between;
  }

  .submission-date{
    align-items: flex-start;
  }

  .created-time{
    align-items: flex-end;
  }
  .title-current-team{
    font-size: 12px;
  } */
}

@media screen and (max-width: 600px) {
   
  .title-card-proposal {
    grid-template-columns: repeat(1, 1fr); /* Two equal columns */
  }
  .title-card-heading-proposal,
  .title-current-team-proposal,
  .submission-date-proposal,
  .DeadLine-date-proposal,
  .created-time-proposal {
    justify-content: flex-start;
  }
  /* .title-card-heading,
  .submission-date,
  .created-time {
    justify-content: space-between;
  }

  .submission-date{
    align-items: flex-start;
  }

  .created-time{
    align-items: flex-end;
  }
  .title-current-team{
    font-size: 12px;
  } */
}


