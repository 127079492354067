.signature_Img{
    width: 200px;
     height: 75px;
}
.Lable_inner_flex{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
}
.Lable_inner_tag{
    width:30% ;
}
.Dropdown_width{
    width: 30%;
}
.FormControl_Tag{
    width: 100%;
}
.sub_parentflex_label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.rightSide_flex{
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.proposal_header_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.proposal_close_button{
    color: red;
}


.proposal_header_tag{
    color:#3d83fa
}

.filename_info_ptag{
    color: red;
}

.common_button_style_red{
    outline: none;
    font-size: 12px;
    border-radius: 10px;
    color: red;
    border: 1px solid red;
    border-color: red;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
  

}


.common_button_style_blue{
    font-size: 12px;
    border-radius: 10px;
    color: #3d83fa;
    outline: none;
    border: 1px solid #3d83fa ;
    border-color: #3d83fa;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
}

.close_Button_style{
    position: absolute;
    right: 3%;
    top: 6%;
}

.duplicate_template_hedar_tag{
  width: 100%
}

.icon_button_padding{
    padding: 0px;
    background-color: transparent;
    outline: none;
    border: none;
}
.templateIcons{
    color:#1976D2
}

.placeholder-editorDiv{


    width:50%;
    position: absolute;
    left:50%;
    top:10%;
    height:100%;
    overflow: auto;

}