.btn-white-outline {
  border: 1px solid white;
  color: white;
}

.btn-white-outline:hover {
  color: white;
}

#newsInput::placeholder {
  padding-left: 3px;
  color: #a2a2a2;
}

.custom-dropdown .nav-link {
  color: blue !important;
}

@media only screen and (max-width: 767px) {
  /* For mobile phones: */
  .card {
    margin-bottom: 5%;
  }

  .ft-46 {
    font-size: 36px;
  }

  .ft_xl {
    font-size: 18px;
  }

  .vl {
    display: none;
  }

  #featureId {
    margin-left: 7%;
  }

  .petLogo {
    width: 190px !important;
    height: 45px !important;
  }

  .ftLogo {
    height: 55% !important;
    margin-top: 3% !important;
  }

  /* .ftIcon {
    width: 22% !important;
    height: 23% !important;
  } */

  .ftIcons {
    margin-left: 5% !important;
  }

  .ftDivTwo {
    margin-top: 10% !important;
    margin-left: 18% !important;
  }

  .navLinks {
    margin-left: 0% !important;
    text-align: center !important;
  }

  .navbar-default .navbar-toggle {
    border-color: white !important;
  }
}

@media only screen and (max-width: 990px) {
  /* For mobile phones: */
  .loginLink {
    margin-left: 0 !important;
  }

  .navLinks {
    margin-left: 0% !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 450px) {
  .res-bg-img {
    height: 75vh !important;
    margin-bottom: 25%;
  }
}

@media only screen and (max-width: 768px) {
  .res-home-maintitle {
    font-size: 35px !important;
    margin: 0% 10% 10% 0%;
  }
  .res-home-maintext {
    font-size: 15px !important;
    margin: 10% 10% 10% 0%;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .res-home-maintitle {
    font-size: 35px !important;
  }
  .res-home-maintext {
    font-size: 17px !important;
  }
  #lappy_image {
    height: 65vh !important;
    margin-top: 12% !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 600px) {
  #lappy_image {
    height: 65vh !important;
    margin-top: 15% !important;
  }
}

@media only screen and (max-width: 600px) and (min-width: 450px) {
  #lappy_image {
    height: 60vh !important;
    margin-top: 25% !important;
  }
}

@media only screen and (max-width: 450px) {
  .res-home-maintitle {
    font-size: 28px !important;
  }
  .res-home-maintext {
    font-size: 14px !important;
  }
  #lappy_image {
    height: 60vh !important;
    margin-top: 30% !important;
  }
}

.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: 0.25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: "Show more";
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: "Show less";
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 0.5em;
  color: white;
  font-size: 0.9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: 0.25em;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  color: #4085f6 !important;
}
