.notification-container {
    margin-bottom: 10px;
    display: flex;
  }
  
  .ReadBtn {
    color: black;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 12px;
  }
  .clearAllBtn{
    margin-left: 155px;
    text-transform: capitalize;
    color: red;
    cursor: pointer;
    font-size: 12px;
  }
  .card {
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }
  
  .card-options {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
  }
  
  .option {
    font-size: 12px;
    cursor: pointer;
  }
  
  .option-divider {
    border-bottom: 1px solid #ccc;
    margin: 5px 0;
  }

  .MuiCardHeader-avatar {
    width: 21%
}

  .rfp-image{
    width: 100%
  }
  