.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.75;
  flex-basis: 75%;
  background: #D5DBDB;
  color: white;
}

.main > h1 {
  font-size: 3em;
}
