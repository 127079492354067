.Farcontainer1 {
    /* width: 100%; */
    /* height: 500px; */
    border-radius: 12px 12px 0 0;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.FarDiv1 {
    width: 269px;
    height: 56px;
    position: absolute;
    top: 1px;
    /* Adjusted to be 10px from the top of Farcontainer */
    left: 1px;
    /* Adjusted to be 10px from the left of Farcontainer */
    border-radius: 12px 12px 0 0;
    padding: 16px 40px;
    background-color: #1A57F2;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.horizontal1 {

    width: 100%;
    position: absolute;
    border: 1px solid #B5B5BA;
    top: 56px;
    left: 2px;

}

.AdditionalDiv1 {
    width: 1080px;
    height: 40px;
    position: absolute;
    display: flex;
    top: 90px;
    left: 20px;
}

.searchBox1 {
    width: 275px;
    height: 40px;
    position: absolute;
    border-radius: 10px;
    margin-left: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #BDBDBD
}

.searchIcon1 {
    float: right;
    margin-right: 10px;
    line-height: 40px;
}

.searchInput1 {
    width: 200px;
    height: 100%;
    float: left;
    border: none;
    outline: none;
    padding: 0 10px;
}

/* .rightDivPart {
   
    width: 108px;
    height: 40px;
    position: absolute;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #1A57F2;
    justify-content: space-between;
    left: 300px;
}
.rightDivPart > p {
    position: absolute;
    top: 10px;
} */

.rightDivPart1 {
    width: 108px;
    height: 40px;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 290px;
    color: #1A57F2;
}

.rightDivPart>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivPart .MuiSvgIcon-root {
    margin-right: 10px;
}

.rightDivSubPart1 {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1A57F2;
}

.rightDivSubPart>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivSection1 {
    width: 130px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 310px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1A57F2;
}

.rightDivSection>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivAdd1 {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 320px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1A57F2;

    color: #fff;
}

.rightDivAdd>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivAdd .MuiSvgIcon-root {
    margin-right: 10px;
}

.rightDivUpload {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 330px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1A57F2;

    color: #fff;
}

.rightDivUpload>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivDelete {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    position: relative;
    left: 340px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff0000;
    border: 1px solid #ff0000;
}

.rightDivFilterAltIcon {
    position: relative;
    left: 350px;
    top: 3px
}

.rightDivFilterAltIcon svg {
    width: 30px;
    height: 30px;
    color: #1A57F2;
}

.mainfardiv1 {
    width: 70%;
    height: 73%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    /* border: 2px solid blueviolet; */
    margin-top: 130px;
    margin-left: 20px;
    overflow-y: auto; 
}

.FarClauseHeading1 {
    margin-top: 20px;
    margin-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    
} 

/* .FarClauseHeading {
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100%; 
    background-color: #fff; 
    z-index: 100; 
    padding-top: 20px; 
    padding-bottom: 20px; 
  } */

.FarClauseHeading>h3 {
    font-weight: 500;
    font-size: 19px;
    color: #1A57F2;
}

.arrowContainer {
    position: absolute;
    border-radius: 10px 0 0 10px;
    top: 270px;
    left: 880px;
    width: 39px;
    height: 70px;
    background-color: #1A57F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
}

.sideFarDiv {
    width: 210px;
    height: 340px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    top: calc(90px + 3px + 40px);
    left: calc(95px + 5px);
    z-index: 1;
    margin-top: 15px;
    margin-left: 820px;
}
.FarClausePartTitles1{
    width: 95%;
    height: 89%;
    /* border: 1px solid blue; */
}
.farCause1 {
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    margin-bottom: 5px; 
    margin-left: 20px;
}

.checkbox1 {
    margin-right: 2px; 
}

.blueIcon1 {
    margin-right: 5px; 
    margin-left: 10px;
    color: blue; 
    cursor: pointer;
}

.numberContainer1 {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    cursor: pointer;
    height: 100%;
 
}
 
.numberItem1 {
    width: 35px;
    height: 30px;
    background-color: #1A57F2;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    margin-bottom: 5px;
    border-radius: 05px;
    transition: background-color 0.3s;
}
 
.numberItem+.numberItem {
    margin-left: 5px;
    margin-bottom: 10px;
    /* Add spacing between divs */
}
 
 
.numberItem {
    width: calc(25% - 5px);
    /* 25% width for each div with 5px margin */
}
.numberItem:hover {
    background-color: #fff;
    color: #1A57F2; /* Change text color on hover */
}
.dropdownMenu1 {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    z-index: 999;
    width: 120px;
    display: none;

}

.rightDivPart:hover .dropdownMenu {
    display: block;
    /* Show the dropdown on hover */
}

.dropdownMenu>div {
    cursor: pointer;
    padding: 5px;
}

.dropdownMenu>div:hover {
    background-color: #f0f0f0;
}

.farCause-container {
    border: 1px solid #ddd; 
    width: 90%;
    height: 20%;
    padding: 5px; 
    background-color: #fff; 
    margin-bottom: 5px; 
}  
.FarcontentDiv {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.FarcontentDiv h2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.FarcontentDiv p {
    font-size: 15px;
    margin-bottom: 10px;
}
.subpartTitle {
    color: #007bff; 
    text-decoration: underline; 
    cursor: pointer;
}

.subpartSection {
    color: #007bff; 
    text-decoration: underline; 
    cursor: pointer;
}



.FarcontentDiv button {
    position: absolute;
    top: 20px;
    /* right:30px; */
     left: 1050px; 
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.FarcontentDiv button:hover {
    background-color: #0056b3;
}

.sideFarDiv {
    transition: transform 0.3s ease;
}

.hidden {
    transform: translateX(100%);
}

.visible {
    transform: translateX(0);
}


/* .subpartDropdownMenu{
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    z-index: 999;
    width: 120px;
    display: none;
} */