.closeIocn{
   color:red 
}

.heading{
    color:#3d83fa
}

.textColor{
    color: blue;
}

.Flex{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.rfpvdiv{
    border: 1px solid grey ;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
}
.parentRFP{
    margin-top: 10px;
}
.joditWith{
    width: 49%;
}

.Main-flex{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    
}

.Flex-one{
    display: flex;
    flex-direction: column;
    width: 16%;
   
}

.Flex-two{
    width: 83%;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
}

.RFP_TITLE{
    margin :0px;
    margin-bottom: 0px;
}

.Flex1{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

}

.title-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Card_Style{
    padding: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.version-No{
    color:#3d83fa
}

.second-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inner-flex{
    width: 80%;
    display: flex;
    align-items: center;
    height: 30px;
}

.inner-flex-1{
    display: flex;
    justify-content: end;
}
.eye_Icon{
    color:#3d83fa
}

.Icon-padding{
    padding: 0px;
}
.person_name{
    margin-right: 5px;
}
.time_div{
    margin-left: 5px;
}

.skyBlueBackground {
    background-color: rgb(236, 95, 13);
    border: 1px solid grey ;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
}