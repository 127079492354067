.colorIcon{
    height:15px;
    cursor:pointer;
    border-radius: 50%;
    width:15px
}
.ColorBlock{
    display:flex;
    justify-content: space-between;
    width:20%;
    margin-top: 30px;
}
.matching_header{
    display:flex;
    width:100%;
    margin-bottom: 5px;
    justify-content: space-between;
}
.matching_header_left{
    width:50%;
}

.container{
    text-align: center;
    margin-top: 20px;
}

.message{
    font-size: 50px;
    margin-bottom: 10px;
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 300px; /* Adjust the width as needed */
    height: 100vh;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .popup.open {
    transform: translateX(0);
  }
  
  /* .popup-content {
    padding: 1rem;
    max-height: calc(100vh - 74px);
    overflow-y: auto;
  } */
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
  }

  /* Styles for sliding effect */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    overflow-y: auto;
    background-color: white;
    z-index: 1300; /* Ensure it’s above other content */
    transition: transform 0.3s ease-in-out;
  }
  
  .modal-enter {
    transform: translateX(100%);
  }
  
  .modal-enter-active {
    transform: translateX(0);
  }
  
  .modal-exit {
    transform: translateX(0);
  }
  
  .modal-exit-active {
    transform: translateX(100%);
  }
  .sliding-div {
    position: absolute;
    top: 7%;
    left: -300px; /* Start position outside of view */
    /* width: 50%; */
    /* height: auto; */
    background-color: white;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  
  .sliding-div.open {
    left: 0; /* End position */
  }
  
  
  
  
