.modalRoleContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 10px;
    background-color: white;
    max-height: 80vh;
    height:400px;
    overflow-y: auto;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* margin-bottom: 10px; */
    height:50px;
    /* border:2px solid red; */
}

.modalHeaderData{
    color:rgb(61, 131, 250);
    font-size: 18px;
    /* bordeR:2px solid green; */
    height:100%;
    display: flex;
    align-items: center;
}
.modalClose{
    color:red;
    font-size: 18px;
}

.formRow {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    /* border:2px solid red; */
}
.formRow div{
    width:100%;
    margin-bottom: 5px;
}

.inputField {
    width:48%;
    /* border:2px solid red; */
    width: calc(50% - 0.5rem);
}
.buttonRow {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.buttonRow > Button {
    margin-right: 1rem; /* Adjust spacing between buttons if needed */
    width:80px;
}

