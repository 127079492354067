@media only screen and (max-width: 450px) {
    .res-shareFile-name{
        font-size: 12px;
    }
    .res-shareFile-dp{
        width: 25px !important;
        height: 25px !important;
    }
    .res-shareFile-option{
        font-size: 10px !important;
    }
}
