.chatarea {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #e5ddd560;
}
.chatarea__container {
  height: 100%;
  width: 100%;
  opacity: 0.06;
  z-index: -100;
  position: absolute;
  background-image: url("https://web.whatsapp.com/img/bg-chat-tile-light_04fcacde539c58cca6745483d4858c52.png");
}

.chatarea__messages {
  display: flex;
  flex-direction: column;
  padding: 17px;
  overflow-y: auto;
  height: 55vh;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f15e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(136, 136, 136, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(116, 115, 115, 0.7);
}

.sendermessage {
  align-self: flex-end;
  background: #dff8ca;
  padding: 5px;
  max-width: 45%;
  min-width: 100px;
  width: fit-content;
  border-radius: 10px;
  border-bottom: 2px solid rgba(37, 37, 37, 0.075);
  margin-bottom: 2px;
}



/* Loader */

