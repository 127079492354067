.cite-button {
    display: inline-block;
    padding: 8px 12px;
    text-decoration: none;
    /* color: #ffffff; */
    /* background-color: #1976d2; */
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
 
  .cite-button:hover {
    background-color: #bdbdbd;
  }
 
  .plus-icon {
    margin-right: 5px;
  }

  #hoverchange{
    display: flex;
    align-items: center;
    justify-content: center;
    height:35px;
    width:35px;
    padding:5px;
  }

  #hoverChange:hover{
    background-color: lightgray;
   border-radius: 50%;
    transition:  0.3s; 
  }