.comment_Flex_Button{
    display: flex;
     align-items: center;
     justify-content: space-between;
     width: 100%;
} 
.comment_modal_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}
.CTAButtons {
    font-size: 12px !important;
    border: 1px solid !important;
    margin-right: 10px !important;
    border-radius: 10px !important;
    color: #1976D2 !important;
    border-color: #1976D2 !important;
    text-transform: capitalize !important;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2) !important;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  }
  .CTAButtons:hover {
    background-color: #1976D2 !important;
    color: white !important;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4) !important; /* Darker shadow */
  }
.flex{
    display: flex;
    align-items: center;
}
.flexbetween{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal_title_{
    color: #1976D2;
    font-weight: bolder;

}
.count_tag_{
    color: #1976D2;
    font-weight: bolder;
    font-size: 18px;
    margin-left: 6px;
}
.modal_closeIcon{
    color: red;
}
.open_button_{
    color: #1976D2;
    padding: 0px;
}

.modal_layout{
    display: flex;
    align-items:center;
    justify-content:center ;
    border-radius: 10px;
    /* background-color: white; */

}
.modal_layout_div{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    min-width: 65%;
    min-height: 350px;
 
}
.modal_div_buttons{
 display: flex;
 justify-content: center;
 margin-top: 30px;
 column-gap: 5px;
}

.note_count_flex{
    display: flex;
    align-items: center;
}
.card_title{
    font-weight:600;
    font-size: 18px;
    color: #1976D2;
    margin-right: 10px;
}

.accordionBtn{
    width:100%;
    height:10%;
    display: flex;
    justify-content: space-around;
}

.accordionBtn button{
    font-size: 15px;
    font-style: bold;
}

.attachmentBox{
    background-color: white;
    width: 80%;
    height: 80%;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    padding : 20px;
}
.mapping-btn{
    width:100%;
    height:10%;

    display:flex;
    justify-content: center;
    margin-top: 20px;
}
.Mapping-DownloadBtn{
    height:10%;
    width:100%;
    display: flex;
    justify-content: end;

}
.reportModalMainDiv{
    
    height: 85%;
    width: 100%;
    overflow: auto;
}


.modal_layout_custom {
    display: flex;
    align-items:center;
    justify-content:center ;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal_content_custom {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    min-width: 250px;
    min-height: 150px;
  }

  .modalCloseDiv{
    display:flex;
    justify-content: end;
    margin-bottom: 10px;
  }

  .expand-icon, .side-modal{
    display: none;
  }

  .document-contents{
    padding: 10px;
  }


  .review-approval-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1300px;
    height: 700px;
    background-color: var(--background-paper, white); /* Adjust the fallback color if needed */
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 16px;
    overflow: auto;
  }

  .review-buttons-mar{
    margin-left: 20px;
  }

  .reviewer-section{
    display: flex;
    justify-content: space-between;
  }


  .title-card {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four equal columns */
    gap: 10px;
    width: 100%;
    padding: 10px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
  }
  
  .title-card-heading,
  .title-current-team,
  .submission-date,
  .created-time {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .title-current-team {
    text-align: center;
    width: fit-content;
    flex-direction: row;
    width: 100%;
  }
  
  .title-current-team .status {
    color: #3ded97;
  }

  .main-editor{
    margin: 10px;
  }

  @media only screen and (max-width: 600px) {

    .row {
        margin: -3% 3%; /* Adjust margins if needed */
        width: 100%; /* Set width to 100% for screens between 501px and 600px */
        box-sizing: border-box; /* Ensure padding is included within the width */
      }

    .CTAButtons {
        font-size: 10px !important;
        width: 29%;
      }


      .add-section-modal{
        width:80%
      }

      .opp-section, .side-panel {
        display: none;
      }

      .expand-icon, .side-modal{
        display:block
      }

      .modal_layout_div{
        min-width: 350px;
      }

      .section-card{
        padding-right: 2px;
      }

      .document-contents{
        margin: 0px -10px;
        padding: 0px;
      }

      .review-approval-box{
        height: 90%;
        width: 97%;
      }
      
      .review-buttons{
        font-size: 10px;
      }

      .review-buttons-mar{
        margin-left: 5px;
      }

      .reviewer-section{
        flex-direction: column;
      }

      .title-card {
        grid-template-columns: repeat(2, 1fr); /* Two equal columns */
      }
    
      .title-card-heading,
      .submission-date,
      .created-time {
        justify-content: space-between;
      }

      .submission-date{
        align-items: flex-start;
      }

      .created-time{
        align-items: flex-end;
      }
      .title-current-team{
        font-size: 12px;
      }

      .word-limit{
        width:40%
      }

      .main-editor{
        margin: 0px
      }

     
    


  }

  @media only screen and (max-width: 375px) {
    .modal_layout_div{
      min-width: 90%;
    }

  }