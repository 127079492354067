@media only screen and (max-width: 700px) {
  .docCont {
    margin-right: 6% !important;
  }

  .NoDocs404 {
    height: 60vh !important;
    width: 90vw !important;
  }

  .shareModal {
    width: 100% !important;
  }

  .docHeadRow {
    margin-left: 3% !important;
  }

  .docPage {
    padding-left: 4% !important;
    padding-right: 5% !important;
  }

  .makeStyles-content-15 {
    padding: 15px !important;
  }
}

@media only screen and (max-width: 600px) {
  .setImgMargin {
    margin-top: 0px;
  }
  .swal2-popup {
    width: 80% !important;
    height: 50% !important;
    margin-right: 2% !important;
  }
  .swal-modal {
    width: 75% !important;
    height: 52% !important;
    margin-left: 13% !important;
    /* padding-right: 30px !important; */
  }
  /* .download{
    margin-right: 5% !important
  } */
}

@media only screen and (min-width: 1200px) {
  .setImgMargin {
    margin-top: -180px;
  }
}

@media only screen and (max-width: 420px) {
  .modalSearchBar {
    width: 100% !important;
  }
}
