@font-face {
  font-family: "Roboto-Regular";
  src: url("../src/font/Roboto-Regular.ttf");
}

@-ms-viewport {
  width: device-width;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../src/font/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Josefin-Slab";
  src: url("../src/font/josefin/JosefinSlab-Regular.ttf");
}

@font-face {
  font-family: "hand-writing";
  src: url("../src/font/handwriting/DancingScript-Regular.otf");
}

:root {
  /* color */
  --grayA: #4d4f5c;
  --grayB: #93979b;
  --grayC: #757575;
  --grayD: #a8a8a8;

  --blueA: #2196f3;
  --blueB: #4285f4;

  --greanA: #34a853;
  --yellowA: #fbbc05;

  /* font family */
  --robotoregular: "Roboto-Regular";

  /* font size */
  --twelve: 0.75em;
  --fourteen: 0.875em;
  --eighteen: 1.125em;
  --twenty: 1.25em;
  --ten: 0.625em;
}

.apexcharts-toolbar {
  opacity: 0 !important;
}

.lcss {
  margin: auto;
}

/* .canvas-container {
  height: 38vh !important;
  width: 90% !important;
} */
body {
  font-family: "Roboto-Regular", sans-serif !important;
  background-color: #f0f0f7 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
link,
li {
  font-family: "Roboto-Regular", sans-serif !important;
}

.rm {
  font-family: "Roboto-Medium", sans-serif !important;
}

.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* color: #fff; */
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: "#f0f0f7" !important;
}

.custom-multiselect-dropdown .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin-bottom: 10px; /* Adjust margin to add space after each option */
}

/* 
.MuiCardContent-root {
  padding-top: 0px !important;
} */

* {
  margin: 0;
  padding: 0;
}

.fcss::first-letter {
  color: yellow;
}

@media only screen and (max-width: 600px) {
  .main-title {
    display: none;
  }
}

.area {
  border: 1px solid #ccc;
  min-height: 400px;
  /* overflow: auto; */
  outline: none;
  padding: 2px;
  resize: both;
  width: 100%;
  font-size: 15px;
  color: #000;
  border-radius: 5px;
  background-color: #fff;
}

.h6-headings {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  font-size: 1rem;
  color: #262626;
}

.sub-headings {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 0.9rem;
  color: #6C6C6C;
}

.sub-headings2 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300;
  font-size: 0.8rem;
  color: #4C4C4C;
}

/* Loader Animation */

.loading-overlay {
  margin: 0;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  overflow: hidden;
}

/* Loader Animation */

/* My Documents CSS */

.blurb-ripple-out {
  border-radius: 50%;

  -webkit-animation: sonarEffect 1.3s ease-out infinite;
  -moz-animation: sonarEffect 1.3s ease-out infinite;
  animation: sonarEffect 1.3s ease-out infinite;
}

@keyframes sonarEffect {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 5px #00e396, 0 0 1px 1px #00e396, 0 0 0 1px #00e396;
  }

  100% {
    box-shadow: 0 0 0 5px #00e396, 0 0 1px 1px #00e396, 0 0 0 1px #00e396;
    transform: scale(1.5);
    opacity: 0;
  }
}

.dashcol {
  margin-right: 3%;
}

div.dashCard {
  background-color: "#4676A5";
  border-radius: "8px";
  box-shadow: "2px 2px 5px -2px #000000";
}

.DashboardCardHover {
  cursor: pointer;
  width: 110%;
  background-color: white;
  border-radius: 10px;
  padding: 10%;
  box-shadow: "2px 2px 7px 1px rgba(0,0,0,0.2)";
}

.DashboardCardHover:hover {
  transform: scale(1.1);
  transition-duration: 0.2s;
}

.SparkLinesCss {
  margin-top: 20%;
}

.dashImage {
  width: 140%;
}

@media only screen and (max-width: 768px) {
  .dashImage {
    width: 170%;
  }

  .DashboardCardHover {
    cursor: pointer;
    width: 140%;
  }
}

@media only screen and (max-width: 600px) {
  .dashImage {
    float: right;
    width: 45%;
  }

  .DashboardCardHover {
    cursor: pointer;
    width: 100%;
    margin: 3%;
  }
}

@media only screen and (max-width: 360px) {
  .dashImage {
    float: right;
    width: 31%;
    margin-top: -30px;
  }

  .DashboardCardHover {
    cursor: pointer;
    width: 100%;
    margin: 3%;
    padding: 7%;
  }
}

@media only screen and (max-width: 320px) {
  .dashImage {
    float: right;
    width: 31%;
    margin-top: -30px;
  }

  .DashboardCardHover {
    cursor: pointer;
    width: 100%;
    margin: 3%;
    padding: 7%;
  }
}

/* @media only screen and (max-width: 411px) {
  .dashImage {
    float: right;
    width: 31%;
    margin-top: -30px;
  }
  .DashboardCardHover {
    cursor: pointer;
    width: 100%;
    margin: 3%;
    padding: 7%;
  }
} */
/* My Documents CSS */

/*  Side Nav Bar CSS */

#appBar {
  background-color: white !important;
}

.drawerMenu {
  /* color: black !important; */
  color: #ffffff !important;
  background-color: #1976D2 !important;
}

.sidenavbar-textlink {
  text-decoration: none;
  color: #ffffff;
}

.sidenavbar-textlink:hover {
  text-decoration: none;
  color: #ffffff;
}

.btn-w {
  width: 200px;
}

li div.MuiListItemIcon-root {
  background-color: transparent !important;
}

/*  Side Nav Bar CSS */

/* Text Editor CSS */
a {
  color: #449efc;
  text-decoration: none;
  font-size: 2vh;
  margin-right: 0.5%;
}

/* .btn {
  margin-right: 0.5%;
  background-color: white;
  border: 1px solid #449efc;
} */

.info {
  border-color: #449efc;
  color: dodgerblue;
}

.info:hover {
  background: #99c9fb;
  color: white;
}

.search-container {
  float: right;
}

::placeholder {
  font-size: 15px;
  padding-left: 6%;
}

.makeStyles-toolbar-12 {
  min-height: 64px !important;
}

.ql-editor {
  min-height: 300px !important;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 100%;
}

/* @media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }

  .topnav a,
  .topnav input[type="text"],
  .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type="text"] {
    border: 1px solid #ccc;
  }
} */
/* Text Editor CSS */

/* List Docs */


.av {
  margin-left: 10%;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

#DocCard {
  background-color: white !important;
}

.MuiPaper-elevation1 {
  /* box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04) !important; */
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  border: solid 1px #e1e1e1 !important;
}

/* List Docs */

.MuiCardHeader-root {
  padding-top: 10px !important;
  padding-bottom: 2px !important;
}

/* document preview csss */
.checked {
  color: orange;
}

.commentField {
  width: 100%;
  height: 70px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dedcdc;
  background-color: #ffffff;
}

/* document preview csss */

/* Alert Component CSS */
.has-search .form-control {
  padding-left: 2.8rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 4.4rem;
  height: 4.4rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #bcbccb;
  padding-left: 3%;
}

/* Alert Component CSS */
.hover-card {
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
  /* filter: blur(5.8px); */
}

/* Pricing Page CSS */
.hover-card:hover {
  border: 1px #66ff99 solid;
  box-shadow: 1px 0px 0px #66ff99;
  transform: scale(1.1);
  transition-duration: 0.5s;
  z-index: 1001;
  cursor: pointer;
}

.currentPlan {
  border: 3px #449efc solid;
}

.cardBorder {
  border: 2px solid #449efc;
}

.icon_color {
  color: #66ff99;
}

@media only screen and (max-width: 992px) {
  .res-col {
    flex: 50% !important;
    max-width: 50% !important;
    margin-bottom: 4%;
  }
}

.lostConnectionCard {
  background-color: #fff !important;
  max-width: 30% !important;
  padding-left: 50% !important;
}

@media only screen and (max-width: 600px) {
  .res-col {
    flex: 100% !important;
    max-width: 100% !important;
    margin-bottom: 5%;
  }
}

/* Pricing Page CSS */

/* Invoice CSS */
.accordian {
  background-color: white !important;
}

/* Invoice CSS */
/* Manage Users */
@media only screen and (max-width: 800px) {
  .userCard-res-col {
    flex: 50% !important;
    max-width: 50% !important;
  }
}

@media only screen and (max-width: 600px) {
  .userCard-res-col {
    flex: 100% !important;
    width: 275px !important;
    max-width: 100% !important;
  }
}

/* Manage Users */

/* TAG CSS */
.input-tag {
  background: white;
  /* border: 1px solid #d6d6d6; */
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
  /* width: 70%; */
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #f1f9ff;
  border-radius: 2px;
  color: #2699fb;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

/* TAG CSS */

/* font size */
.ft_xs {
  font-size: 12px !important;
}

.makeStyles-content-14 {
  padding: 0px !important;
  flex-grow: 1;
}

.ft_sm {
  font-size: 14px !important;
}

.ft_md {
  font-size: 16px;
}

.ft_lg {
  font-size: 18px;
}

.ft_xl {
  font-size: 20px;
}

.ft-46 {
  font-size: 46px;
}

.ft-22 {
  font-size: 22px;
}

.ft-28 {
  font-size: 28px;
}

.ft-36 {
  font-size: 36px;
}

.ft-wt-500 {
  font-weight: 500;
}

/* .error {
  color: red;
} */

.bg-gray {
  background-color: #f0f0f7 !important;
}

.bg_white {
  background-color: #fafafa !important;
}

.text-a {
  color: #43425d;
}

.text-b {
  color: #737373;
}

.text-c {
  color: #4d4f5c;
}

.text-d {
  color: #707070;
}

.text-e {
  color: #777274;
}

.text-f {
  color: #231f20;
}

.text-g {
  color: #3b3b3b;
}

.text-h {
  color: #5b6b82;
}

.point {
  cursor: pointer;
}

.setShadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
}

/*netwrok error */
.internet-error {
  height: 60px;
  background: #ff8100;
  margin-top: 0;
  font-size: 20px;
}

.internet-error p {
  font-size: 25px;
  line-height: 60px;
  color: #fff;
  margin: 0;
}

/* netwrok error */

.img-50 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.img-25 {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.removeBorder {
  border: none;
}

.rm-focus input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.chngPass:hover {
  color: #008ae6;
}

.del:hover {
  color: red;
}

.find:hover {
  background-color: #008ae6;
  color: white;
  border-radius: 3px;
}

#blogtitle:hover {
  color: #008ae6;
}

.parent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.parent:hover .child,
.parent:focus .child {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.parent:hover .child:before,
.parent:focus .child:before {
  display: block;
}

.child:before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(52,73,94,0.75); */
}

/* Overlap DP */
.avatars {
  display: inline-flex;
  flex-direction: reverse;
  padding-left: 50px;
}

.avatar {
  margin-left: -25px;      /* Only defined in the first rule */
  position: relative;      /* Only defined in the first rule */
  border: 1px solid #fff;  /* Only defined in the first rule */
  border-radius: 50%;      /* Common property */
  overflow: hidden;        /* Only defined in the first rule */
  width: 50px;             /* Use the width from the second rule */
  height: 50px;            /* Use the height from the second rule */
  vertical-align: middle;  /* Only defined in the second rule */
}

.avatar img {
  width: 50px;
  height: 50px;
}

.drop_set {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform 0.2s;
  /* Animation */
  /* width: 200px; */
  /* height: 200px; */
  margin: 0 auto;
  color: white;
  border: solid 1px white;
}

.drop_set:hover {
  transform: scale(1.2);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .canvas-container {
    height: 100% !important;
    /* width: 100% !important; */
  }
}

#tapp {
  font-family: sans-serif;
  text-align: center !important;
  background-color: #fff !important;
  max-width: 100% !important;
}

.teamDP {
  margin: auto;
  z-index: 0;
  margin-bottom: -29px;
  /* padding-top: -10% !important; */
}

#teamMemName {
  padding-top: 12px;
}

.App-divider-337 {
  margin: 13px 0 !important;
}

.App-divider-278 {
  margin: 5px 0 !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.App-divider-307 {
  margin: 6px 0 !important;
}

.App-divider-288 {
  margin: 5px 0 !important;
}

@media only screen and (max-width: 600px) {
  .teamcard {
    margin-top: 5% !important;
  }

  .App-divider-278 {
    margin: 5px 0 !important;
  }
}

@media only screen and (max-width: 480px) {
  .picker-panel {
    /* float: left !important; */
    position: initial !important;
    margin-left: 30% !important;
  }
}

#dictHr {
  margin-top: 0 !important;
}

.picker-panel {
  z-index: 1;
  margin-left: -60% !important;
}

.footer-btn {
  display: none !important;
}

/* .basic */

.PrivateTabIndicator-root-287 {
  height: 4px !important;
  color: #1976d2 !important;
}

.bubble-right {
  vertical-align: middle;
  /* -webkit-transform: perspective(1px) translateZ(0); */
  /* transform: perspective(1px) translateZ(0); */
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: sticky;
}

.bubble-right:active::before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  border-style: solid;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  top: calc(50% - 10px);
  right: 0;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #1976d2;
}

.bubble-right:active:before {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.citations {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: #7d8ffd;
}

.selectedText {
  background-color: #fedddd;
}

.MuiDialogContent-root {
  background-color: #ffff;
}

.MuiDialogTitle-root {
  background-color: #ffff;
}

.MuiDialogActions-root {
  background-color: #ffff;
}

.MuiOutlinedInput-multiline {
  padding: 18.5px 14px;
  width: 273px !important;
}

.citeStar {
  background-color: orange;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #007bff !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #007bff !important;
}

.stepActive:hover {
  background-color: var(--grayD);
  cursor: pointer;
  opacity: 0.7;
}

.phone {
  width: 50% !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #007bff !important;
}

.PrivateTabIndicator-colorPrimary-304 {
  background-color: #007bff !important;
}

.PrivateTabIndicator-colorPrimary-474 {
  background-color: #007bff !important;
}

/* .dropdown-menu.show {
width: 36%;
} */

/* .MuiPopover-paper {
  height: 100px !important;
  width: 50px !important;
  overflow-y: auto !important;
} */
.disableButtons {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.enableButtons {
  cursor: pointer;
}


.verifyMsg {
  font-size: 14px;
  font-weight: bolder;
  color: #18A350;
}

.tickicon {
  Width: 13.96px;
  Height: 13.96px;
}

.errorMsg {
  font-size: 14px;
  font-weight: bolder;
  color: red;
}

.featurebutton{
  width: 130px;
}

/* ProgressBarComp CSS start */
.pBCompWrapper {
  border: 1px solid black;
}

.pBCompContainer {
  background-color: pink;
}

/* ProgressBarComp CSS end */

/* TagButtonComp CSS start */
.tBAppearance {
  border-radius: 8px;
  border-style: none;
  box-shadow: 2px 2px 5px -2px #000000;
  color: #5E5E5E !important;
}

.btn-custom{
  font-family:"Poppins", sans-serif;
  font-weight:300!important;
  border-radius: "8px" !important;
  border-style:"none";
  box-shadow: "2px 2px 5px -2px #000000" !important;
  color: "#5E5E5E" !important;
  padding: "10px" !important;
  background-color: "#ffffff"!important;
  outline: "none"!important
}
.btn-outline-secondary{
  border-radius: 8px !important;
  border-style:none;
  box-shadow: 2px 2px 5px -2px #000000 !important;
  color: #5E5E5E !important;
  padding: 10px !important;
  background-color: #ffffff!important;
  outline: none !important
}

.btn-outline-secondary:hover {
  border-radius: 8px !important;
  border-style:none;
  color:white !important;
  background-color: #82C1FF !important;
  border-color: #82C1FF !important;
  box-shadow: 2px 2px 5px -2px #000000 !important;
}
.btn-outline-secondary:focus {
  border-radius: 8px !important;
  border-style:none;
  color:white !important;
  background-color: #82C1FF !important;
  border-color: #82C1FF !important;
  box-shadow: 2px 2px 5px -2px #000000 !important;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d !important;
  background-color: transparent !important;
}

/* .tagbutton::after{
  font-family:"Poppins, sans-serif";
  font-weight:300;
  border-radius: "8px";
  border-style:"none";
  box-shadow: "2px 2px 5px -2px #000000";
  color: "white";
  padding: "10px";
  background-color: "#82C1FF";
  outline: "none"
} */

/* TagButtonComp CSS end */


/* CheckBoxMui CSS start */
.checkbox-container{
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.checkbox-container.hidden{
  opacity: 0;
  pointer-events: none;
}


/* CheckBoxMui CSS start */

/* editor Right side css */
.bottomeditor{
  border-radius: 8px;
  border-style:"none";
  border: 1px solid #D6D6D6;
  /* min-width: "475px";
  min-height: "57px"; */
  padding: 10px !important;
}

.rightsideeditor{
  font-size: 14px !important;
  font-weight: 400 !important;
}
.trash {
  background-color: transparent;
  cursor: pointer;
  color: gray;
  border: none;
  outline: none;
}

/* mui dropdown changes */
.MuiPopover-paper{
  background-color: white !important;
  width: auto !important;
  height: auto !important;
}
/* for modal bg */
.modal-backdrop.show {
  opacity: .5;
  z-index: 1000;
}

.breadcrumb-item.active {
  color: #6c757d;
  background-color: transparent !important;
  border: none !important;
}



.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.graph-container {
  width: 100%;
  height: 400px;
  border: 1px solid #ccc;
}

.button-container {
  margin-top: 20px;
}

.button-container button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-container button:hover {
  background-color: #0056b3;
}

.makeStyles-content-16 {
  padding-left: 0px !important;
}

.graph-c{
  width: 100%;
}

.sigCanvas {
  border: 1px solid #000;
}
