@media only screen and (max-width: 700px) {
  .shareBtn {
    margin-right: 22% !important;
    margin-top: 5% !important;
  }

  .doc {
    margin-top: 15% !important;
  }

  .modal {
    margin-top: 10% !important;
    margin-left: 20% !important;
    width: 75% !important;
  }

  .modalSearchBar {
    width: 150% !important;
  }

  .doctitle {
    margin-top: 10% !important;
  }

  .input-tag {
    width: 100% !important;
  }
}

@media only screen and (max-width: 420px) {
  .modalSearchBar {
    width: 200% !important;
  }

  .doctitle {
    margin-bottom: 5% !important;
  }
}
