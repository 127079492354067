/* .weaken {
  border-bottom: solid 3px var(--weaken);
}

.ignore {
  font-size: 14px;
  border-top:#f7f7f7 solid 1px;
}

.ignore:hover{
  background-color:#f7f7f7;
  cursor: pointer;
}

.passive {
  border-bottom: solid 3px var(--passive);
}

.TYPOS{
  border-bottom: solid 3px var(--TYPOS);
}
.repeat {
  border-bottom: solid 3px var(--repeat);
}

.effectivevocabulary{
  border-bottom: solid 3px var(--effectivevocabulary);
}

.qualifier{
  border-bottom: solid 3px var(--qualifier);
}

.complex{
  border-bottom: solid 3px var(--complex);
}

.hard{
  border-bottom: solid 3px var(--hard);
}

.bold{
  font-weight: 700;
}

.space {
  border-bottom: solid 3px var(--overuse);
}

.error {
  border-bottom: solid 3px var(--error);
}

.error:hover {
  background-color: #fedddd;
  transition-property: background-color;
  transition-duration: 1s;
} */



.overuse {
  border-bottom: solid 3px var(--overuse);
  z-index: 100;
  /* background-color:var(--overuse); */
  /* text-decoration: underline;
  text-decoration-color: rgba(217, 83, 79, 0.8); */
}

.spell-mistake {
  border-bottom: solid 3px var(--error);
}


.shadow_ {
  box-shadow: 1px 1px 3px gray;
}
.suggest {
  min-width: 15%;
  max-width: 20%;
  position: absolute;
  margin-left: -3px;
  margin-top: 3px;
}

.suggesion-hover {
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  border: 1px solid white;
  font-size: 14px;
  position: absolute;
  cursor: context-menu;
  margin-top: 4px;
  margin-left: -60px;
}

.btnFont {
  background-color: transparent;
  border: none;
  font-size: 18px;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #5890ff;
}

.identify {
  /* border: 1px solid #ddd; */
  /* border-top: 0px solid #ddd; */
  font-size: 14px;
}
.identify > div {
  margin: 0px 5px 0px 5px;
}

.setBgA {
  background-color: rgb(218, 231, 248);
}

.imgSet {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.editor {
  box-sizing: border-box;
  /* border: 1px solid #ddd; */
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  /* margin-bottom: 2em; */
  /* box-shadow: inset 0px 1px 8px -3px #ABABAB; */
  background: #fefefe;
  /* min-height: 240px; */
}

.editor .public-DraftEditor-content {
  min-height: 300px;
}

.editor .public-DraftEditorPlaceholder-root {
  color: gray;
  opacity: 0.4;
}

/* .editor .public-DraftEditorPlaceholder-root {
  display: none;
} */

.bgSetB {
  background: #f7f7f7;
  font-weight: 500;
  font-family: "Roboto-Medium", sans-serif;
}

.select {
  color: blue;
  /* font-weight: 600; */
  display: inline-block;
  font-size: 14px;
}
.select:hover {
  /* background-color: blue; */
  /* color: whitesmoke; */
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #888888;
  cursor: pointer;
  font-size: 15px;
  margin-right: 16px;
  padding: 2px 0;
  font-weight: 400;
  display: inline-block;
}

.RichEditor-styleButton:hover {
  color: black;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.draftJsToolbar__toolbar__dNtBH {
  margin-left: 10% !important;
  width: 20% !important;
}

.setDropdown{
  min-width: 100px;
  /* border: 0px; */
}


.rw-widget-container{
  border: 0px !important;
}

#color .rw-widget-input{
box-shadow: 0px 0px 0px 0px white !important;
}

 #docTitle::placeholder{
  color: var(--grayA);
  opacity: 0.6;
  padding-left: 5px;
  font-size: 1em;
  font-family: var(--robotoregular);
}

#tags::placeholder{
  color: var(--grayA);
  opacity: 0.6;
  padding-left: 15px;
  font-size: 1em;
  font-family: var(--robotoregular);
}

#docTitle{
  border:0;
  width: 100%;
  margin: 0;
  font-size: 1.6em;
  color: var(--grayA);
  font-family: var(--robotoregular);
  padding: 10px 15px 10px 15px;
}

#docTitle:focus{
  outline: none;
}

.mydrop{
  color: var(--grayA);
  font-family: var(--robotoregular);
}

.itemList{
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.36);
  background-color: #ffffff;
  padding: 7px 2px 7px 7px;
  border-radius: 0 3px 3px 0;
  border-right: solid 1px rgba(0, 0, 0, 0.08);
  color: var(--grayB)
}

.itemHead{
  font-size: var(--eighteen);
  margin-bottom: 5px;
}

.itemContent{
  font-size: var(--ten);
  color: var(--grayB)
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.08); 
}

.iconSize{
  font-size: var(--eighteen);
  color: var(--blueA)
}

.assist{
  border: solid 1px #bce0fd;
  border-radius: 19px;
  padding: 2px;
  cursor: pointer;
  transition: box-shadow .2s;
  color: var( --grayC);
}

.assist:hover{
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.36);
}


.radioSize{
  font-size: var(--twelve);
  color: var(--grayB);
  margin: 0;
}

.flex_center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bgSet{
  background-color: green !important;
}

.bg_all{
  background-color: #5890ff;
  color: red;
}

.allAlert{
  font-size: var(--fourteen);
  color: var(--grayD);
  margin: 0;
}

.setAvatar{
  height: 25px !important;
  width: 25px !important;
  /* background-color: #4285f4 !important; */
  font-size: var(--twelve) !important;
  margin-right: 5px;
}

.addOpacity{
  opacity: 0.4;
}

.removeOpacity{
  opacity:1;
}

.sticky {
  /* position: -webkit-sticky; */
  position: sticky;
  top: 50px;
  /* background-color: yellow; */
   /* padding: 50px; */
 /* font-size: 20px; */
}

.scroll{
  width: 100%;
    height: 68vh;
    overflow-y: scroll;
    overflow-x:hidden;
}

.scrollItem{
  /* width: 100%; */
  height: 70vh;
  overflow-y: scroll;
  overflow-x:hidden;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.example-enter {
opacity: 0.01;
}

.example-enter.example-enter-active {
opacity: 1;
transition: opacity 500ms ease-in;
}

.example-leave {
opacity: 1;
}

.example-leave.example-leave-active {
opacity: 0.01;
transition: opacity 300ms ease-in;
}


::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(182, 182, 182) !important; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important; 
}


.animate {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.hideCorr{
  position: absolute;
  width: 0;
  opacity: 0;
}

/* #editorId img {
  height: 100px;
  width: 100px;
} */


.SentenceCardHighlight{
  background-color:#8CC7FB !important;
  color : white !important;
}
/* 
.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
} */

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  position: absolute;
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  /* vertical-align: bottom; */
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}