  /* UserListModal.css */
  .modalshr {
    display: flex;
    /* position: fixed; */
    z-index: 1;
    /* left: 0;
    top: 0; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modalshr.open {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
    /* border:2px solid brown; */
    margin-top:3%;
    margin-left: 2.9%;
  }
  
  .modalshr-content {
    background-color: #fefefe;
    /* margin: auto; */
    padding: 2%;
    border: 1px solid #888;
    width: 60%;
    height:85%;
    border-radius: 15px;
    /* border:2px solid blue; */
  }

  .modalshr-content-data {
    background-color: #fefefe;
    /* margin:  auto; */
    padding: 2%;
    border: 1px solid #888;
    width: 60%;
    height:75%;
    border-radius: 15px;
    /* border:2px solid pink; */
  }
  
  .closeshr-btn img{
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    height: 25px
  }
  
  .closeshr-btn:hover,
  .closeshr-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .headingContainer {
    display: flex;
    justify-content: space-between;
 
  }
  .addMembContainer {
    display: flex;
    justify-content: space-between;
    margin-top:20px
  }
  .addMemberBox { 
    border: 1px solid gray;
     padding: 15px;
     border-radius: 10px;
     width: 100%;
     margin-right: 20px;
     position: relative
  }
  .addMemberName {
      position: absolute;
      top: -10px;
      left: 10px;
      font-size: 12px;
      background-color: white;
      padding: 0 10px;
      color:gray
  }
  .RfpBtnStl {
    color: white;
    background-color: blue;
    border-radius: 10px;
    padding: 9px;
    width: 70px
  }
 

  .usershr-list-container {
    height: 200px; /* Set the height as needed */
    overflow-y: auto; /* Add a vertical scrollbar when content overflows */
    border: 1px solid lightgray; /* Add black border */
    border-radius: 10px;
  }
  
  .usershr-list {
    margin-top: 20px;
  }
  
  .usershr {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .left-section {
    display: flex;
    align-items: center;
  }
  
  .profile-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    margin-left: 10px
  }
  
  .profile-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .infoshr {
    flex-grow: 1;
  }
  
  .nameshr{
    margin-bottom: 0px;
    color:black
  }

  .emailshr {
    margin-bottom: 10px;
    color:black;
    font-size: 10px;
  }
  
  .right-section {
    display: flex;
    align-items: center;
  }
  
  .dropdownshr {
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px;
    border-color: gray;
  }
  
  .checkbox {
    display: none;
  }
  
  .checkbox + .checkbox-label {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 15px; /* Change to make the checkbox square */
    border: 2px solid #ccc;
    margin-right: 10px;
    margin-left: 20px;
  }
  
  .checkbox:checked + .checkbox-label:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green; /* Green color */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .prechecked {
    opacity: 0.5;
    }
  

  .commentshr-box {
    margin-top: 20px;
  }
  
  .commentshr-box h3 {
    margin-bottom: 10px;
  }
  
  .commentshr-textarea {
    width: 99%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 7px;
  }
  .headingShr{
    color:#1976D2;
  }
  
  .MainheadingShr{
    color:rgb(61, 131, 250)
  }

/* ShareAssetsPage.css */
.selected-users-box {
    margin-top: 20px;
  }
  
  .selected-users-box h3 {
    margin-bottom: 10px;
    color: gray;
  }
  
  .selected-users {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .selected-user {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 1px 5px;
    border:1px solid gray
  }
  
  .selected-user .profile-icon {
    margin-right: 5px;
  }
  
  .selected-user .nameshr {
    color: black;
  }
  
  .selected-user .nameshr::after {
    content: ',';
  }
  
  .selected-user:last-child .nameshr::after {
    content: '';
  }
  .discard-icon {
    cursor: pointer;
    color: #999;
    font-size: 20px;
    margin-left:10px
  }
  
  .discard-icon:hover {
    color: #555;
  }
  
 .errorMessage{
  color:red;
  margin-top: 6px;
 }

 .existingMembersMsg{
  color:orange;
  margin-top: 6px;
 }