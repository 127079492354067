@media only screen and (max-width: 600px) {
  .MuiList-root {
    padding-top: 40px !important;
  }
}

#menuList {
  padding-top: 15px !important;
}

.makeStyles-drawerOpen-10 {
  width: 250px !important;
}
/* 
.MuiList-padding {
  padding-top: 45px !important;
} */
