.editable{
    min-height: 70vh;
    max-height: 70vh;
    width: 100%;
    background-color: white;
    border-radius: 1px;
    border: none;
    padding-top: 20px !important;
    padding-left: 10px !important;
    padding-right: 40px !important;
    padding-bottom: 50px !important;
    font-size: 18px;
    /* box-shadow: 5px 5px 10px rgba(0,0,0,0.3); */
    text-align: justify !important;
}

/* .editable:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid rgba(81, 203, 238, 1);

} */
.editableOne{
    min-height: 70vh;
    max-height: 70vh;
    width: 100%;
    background-color: white;
    border-radius: 1px;
    border: none;
    /* border: solid 2px #2196f3; */
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 50px;
    padding-bottom: 50px;
    font-size: 18px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
    /* background: url(./../../static/writeA.png) center center no-repeat; 
    background-size: 351px 277px; */
}

/* compontent css */
.trash {
    background-color: transparent;
    cursor: pointer;
    color: gray;
    border: none;
    outline: none;
}

.hrdash {
    border-top: 1px dashed #1976d2;
}

.logo {
    height: 30px;
    width: 30px;
}

.pet_out {
    /* border: solid 1px #2196f3; */
    border-radius: 20px;
    cursor: pointer;
    background-color: #1976d2;

}

.moveup {
    /* margin-top: -7px; */
    padding: 12px;
    background-color: white;
    /* z-index: 10;
    position: relative; */
}

.sidebtn{

    position: absolute;
    right: 16px;
    border-top-right-radius: 3px;
    top: -7px;
}

.sidebtn > ul {
    list-style-type: none;
    margin: 0px;
    padding-top: 8px;
}

.sidebtn > ul > li {
    /* cursor: pointer; */
    /* color: white; */
    color: white;
    /* padding: 0px 5px; */
    background-color: #1976d2;
    opacity: 0.40;
    margin: 0px;
    /* padding: 5px; */
}

.sidebtn > ul > li:hover {
    cursor: pointer;
    color: white;
    opacity: 1;
    background-color: #1976d2;
}

.sidebtn > ul > li > button:hover {
    color: white;
}

.enableIcon{
    cursor: pointer !important;
    color: white !important;
    opacity: 1 !important;
    background-color: #1976d2 !important;
}

.disableIcon{
    cursor: pointer;
    color: black;
    opacity: 0.40;
    opacity: 1;
    background-color: #1976d2;
}

.btn_trns{
    /* background-color: none; */
    background-color: transparent;
    border: none;
    outline:none;
    /* width: 100%; */
}

.btn_side{
    background-color: transparent;
    border: none;
    outline:none;
    width: 100%;
    padding: 5px;
    /* color: white; */
}

.pet_out:hover {
    background-color:  #84b8ec;
    cursor: pointer;
    color: #fff;
}


.pet_clicked{
    /* border: solid 1px #84b8ec; */
    background-color:  #1976d2;
    border-radius: 20px;
    cursor: pointer;
    color: #fff;
    margin-bottom: 12px !important;
    /* 84b8ec */
}

.charaLimitCSS{
    border-radius: 20px;
    color: #fff;
    /* border: solid 1px #1976d2; */
    background-color:  #1976d2;
    pointer-events:none;
    opacity: 0.5;
    /* margin-bottom: 12px !important; */
}

.swapContainer {
    width: 50px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swapContainer div {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .swapContainer:hover {
    background-color: #cac8c8;
  }
  
  .swapIcon {
    color: #7C7C7C;
    cursor: pointer;
  }
  
  .borderDanger {
    border: 1px solid red; /* Or any desired border color */
  }
  