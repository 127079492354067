/* Styles for the modal */
.modal-dialog {
    max-width: 1300px;
  }
  
  /* Styles for the overlay when modal is open */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
.custom-dropdown-item:hover {
  background-color: lightblue !important;
}

.changedlayout {
    display: flex;
    width: 100%;
    /* height: 50px; */
    padding: 15px;
    justify-content: space-between;
    align-items: center;
}

  .modal-overlay p {
    color: #1976D2;
    font-size: 20px;
  }
  
  /* Styles for the modal content */
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 0px;
    border-radius: 8px;
    width: 100%;
    max-width: 1200px;
    /* max-height: 100vh; */
    height: 55vh;
    overflow-y: auto;
  }
  
  /* Styles for the close button in the modal */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  /* Styles for the delete modal */
  .delete-modal {
    z-index: 99999;
    width: 650px;
    margin-left: 450px

  }
  
  /* Styles for the deactivate modal */
  .deactivate-modal {
    z-index: 99999;
    width: 700px;
    margin-left: 480px;
  }
  
  /* Styles for the buttons in the modals */
  .modal-footer button {
    margin-left: 5px;
  }
  
  .orgID{
    display: flex;
    width: 1150px;
    height: 50px;
  }

  .orgID Button{
    margin-left: 30px;
    width: 90px;
    height: 40px;
    border-radius: 15px;
    background-color: #1976D2;
  }

  .orgID h6{
    margin-top: 12PX;
  }

.MuiSwitch-root {
  width: 40px; 
  height: 20px; 
  padding: 0;
}

.MuiSwitch-thumb {
  width: 20px; 
  height: 20px; 
}

.MuiSwitch-track {
  border-radius: 10px; 
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #28a745 !important; 
}

.MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb {
  background-color: #28a745; 
}

.MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb::before {
  content: '\2714'; 
  color: #fff;
  font-size: 14px; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiSwitch-colorPrimary:not(.Mui-checked) + .MuiSwitch-track {
  background-color: #ed4f4a; 
}

.MuiSwitch-colorPrimary:not(.Mui-checked) .MuiSwitch-thumb::before {
  content: '\2716'; 
  color: #fff; 
  font-size: 14px; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.delete-modal .modal-content {
  height: 220px; 
}
.deactivate-modal .modal-content {
  height: 320px; 
}
.user-delete-modal .modal-content{
  height: 220px;
  width: 650px;
}
.user-deactivate-modal .modal-content{
  height: 320px;
  width: 700px;
}

/* export functionality */
.export-dropdown {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.dropdown-item {
  font-size: 12px; 
}

.export-dropdown .dropdown-toggle {
  min-width: 100px; 
}
