.lostConnectionCard {
  padding-left: 20px !important;
  padding-right: 20px !important;
  /* margin-left: 100px !important;
  margin-right: 20px !important; */
  padding-top: 4% !important;
  background-color: rgb(67, 66, 93) !important;
}

.load {
  width: 30%;
  margin-left: 35%;
  margin-top: 1%;
}

@media only screen and (max-width: 767px) {
  .load {
    width: 60% !important;
    margin-left: 21% !important;
  }

  #exCircle {
    margin-top: 14% !important;
    padding-left: 3% !important;
  }
}

@media (min-width: 1521px) and (max-width: 2165px) {
  #exCircle {
    margin-top: 3% !important;
    padding-left: 5% !important;
  }
}
