/* Custom checkbox styles */
.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .custom-checkbox .checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #0011ffe4; /* Change border color as needed */
    border-radius: 3px;
  }
  
  .custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #0011ffe4; /* Change tick color as needed */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  

  .custom-checkbox .form-control-label {
    margin-left: 30px; /* Adjust as needed */
  }
  .dialog {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 980px;
    max-height: 500px;
    margin: 0 auto;
  }
  
  .dialog-title {
    color: #007bff;
    font-size: 20px;
    display: flex;
    width:100%;
    justify-content: space-between;
    /* border:2px solid red; */
  }
   .dialogRolename{
    width:50%;
    margin-bottom: 10px;
    /* border:2px solid red; */
  }
 /* .dialogCancel{
    width:50%;
    border:2px solid green;
  } */
  
  .dialog-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border:2px solid green;
  }
  
  .dialog-close-icon {
    color: #ff0000;
    /* bordeR:2px solid green; */
  }
  
  .dialog-backdrop {
    background-color: rgba(0, 0, 0, 0);
  }