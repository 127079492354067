/* Example CSS */

  
  .topictitle1 {
    font-size: 24px;
    font-weight: bold;  
    margin-top:60px;
    padding-bottom: 10px;
    border-bottom: 2px solid lightgray;
  }
  .topictitle2{
    font-size: 28px;
    color:blue;
    font-weight: bold;  
    padding-top:20px;
    margin-bottom: 20px;
  }
  .familylinks{
    margin-top:20px;
  }

  .parentlink strong{
    color:blue
  }

  /* p{
    font-size: 20px;
    line-height: 1.285em
  } */
  
  .ListL1 {
    margin-left: 20px;
  }
  
  .ListL2 {
    margin-left: 40px;
  }
  
  .ListL3 {
    margin-left: 60px;
  }
  
  /* Add more styles as needed */
  /* .Far-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;  
    margin-top: -24px;
    border-radius: 12px 12px 0 0;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0 16px; 
    box-sizing: border-box; 
  } */

  .Far-container {
    width: 100%;
    max-width: 100%; /* Ensure it doesn't exceed the viewport width */
    margin: 0 auto;  /* Center the container */
    margin-top: -24px;
    border-radius: 12px 12px 0 0;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0 16px; /* Optional padding to give some space inside */
    box-sizing: border-box; /* Include padding and border in the element's width and height */
    position: relative; /* Ensures arrowContainer can be positioned relative to this container */
  }

.FarDiv {
    /* width: 269px; */
    height: 56px;
    display: flex;
    justify-content: center;
    text-align: center;
    /* position: absolute;
    top: 1px; */
    /* Adjusted to be 10px from the top of Farcontainer */
    /* left: 1px; */
    /* Adjusted to be 10px from the left of Farcontainer */
    border-radius: 12px 12px 0 0;
    padding: 16px 40px;
    background-color: #1976D2 ;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    /* margin-right: 18px; */
}
.FarDivContainer {
    width:60%;
    display: flex;
    align-items: center;
    justify-content: left;
    column-gap:13px;
    /* margin-right: 13px;  */
  }
.inactiveFarDiv{
    background-color: #ceced2;
    color:#676769;
}
.clauseDate{
    width:67%;
    display:flex;
    flex-direction: column;
    justify-content: right;
    align-items:end;
    color:#676769;
    /* border:2px solid red; */
}
.clauseInnerDate{
    /* width: 35%; */
    height:100%;
    display: flex;
    flex-direction: column;
    /* margin-left: 310px; */
    /* border:2px solid blue; */
}
.tabButton{
width:10%;
height:100%;
/* background-color: #0056b3; */
outline:none;
display:block;
}
.tabData{
    width:80%;
    height:100%;
    display:flex;
    align-items: center;
    font-size: 16px;
}
.selectedTab{
    margin-left:5px;
    color: black
}
.mainFarTab{
    display:flex;
    /* height:56px; */
    align-items: center;
    flex-wrap:nowrap;
    column-gap:10px;
}
.MainBedscrum{
    /* color:rgb(61, 131, 250); */
    color: black;
    font-weight: bold;
}
.tabView{
    height:40px;
    display:flex;
    width:100%;
    justify-content: space-between;
    background-color: white;
    /* margin-bottom: 30px; */
    border-bottom:1px solid lightgray;
    /* margin-left: 25px; */
    padding-left:25px;
    /* padding-right: 2px; */
    /* margin-top: 20px; */
    align-items: center;
}

.horizontal {

    width: 100%;
    margin-top:-0.2%;
    /* position: absolute; */
    border: 1px solid #d9d9d9;
    /* top: 56px; */
    /* left: 2px; */

}

.AdditionalDiv {
    width: 100%;
    /* height: 50px; */
    margin-top: 10px;
    /* margin-bottom:30px; */
    padding-left: 10px;
    /* position: absolute; */
    display: flex;
    /* justify-content: space-between; */
    justify-content:start;
    column-gap:10px;
    row-gap:5px;
    /* top: 90px;
    left: 20px; */;
    flex-wrap: wrap;
}
.MainSearchBox{
    width:35%;
}

.searchBox {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center; /* Center the content vertically */
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #BDBDBD;
    padding: 0 10px; /* Add some padding for spacing */
    box-sizing: border-box; /* Ensure padding is included in the width */
  }

.searchIcon {
    float: right;
    margin-right: 10px;
    margin-left: 10px;
    line-height: 40px;
}

.searchInput {
    flex: 1; /* Take up the remaining space */
    height: 100%;
    border: none;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
    box-sizing: border-box; /* Include padding in the width */
  }
.searchInput::placeholder {
    font-weight: 100; /* Adjust the font weight as needed */
  }
/* .rightDivPart {
   
    width: 108px;
    height: 40px;
    position: absolute;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #1A57F2;
    justify-content: space-between;
    left: 300px;
}
.rightDivPart > p {
    position: absolute;
    top: 10px;
} */

.rightDivPart {
    width: 100%;
    height: 40px;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 290px;
    color: #1A57F2;
    padding-left: 10px;
    font-size:16px;
    margin-right:20px;
}

/* .rightDivPart>p {
    margin-left: 10px;
    margin-top: 10px;
} */
.partDrop{
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivPart .MuiSvgIcon-root {
    margin-right: 10px;
}

.rightDivSubPart {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1A57F2;
}

.rightDivSubPart>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivSection {
    width: 130px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 310px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1A57F2;
}

.rightDivSection>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivAdd {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 285px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1A57F2;
    color: #fff;
}

.rightDivAdd>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivAdd .MuiSvgIcon-root {
    margin-right: 10px;
}

.rightDivUpload {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    border: 1px solid #BDBDBD;
    position: relative;
    left: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1A57F2;

    color: #fff;
}

.rightDivUpload>p {
    margin-left: 10px;
    margin-top: 10px;
}

.rightDivDelete {
    width: 110px;
    /* Set width to 130px */
    height: 40px;
    position: relative;
    left: 315px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff0000;
    border: 1px solid #ff0000;
}

.rightDivFilterAltIcon {
    position: relative;
    left: 330px;
    top: 3px
}

.rightDivFilterAltIcon svg {
    width: 30px;
    height: 30px;
    color: #1A57F2;
}

.mainfardiv {
    width: 100%;
    height: 73%;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
    /* border: 2px solid blueviolet; */
    margin-top: 5px;
    margin-left: 20px;
    overflow-y: auto; 
}

.FarClauseHeading {
    margin-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    
} 

/* .FarClauseHeading {
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100%; 
    background-color: #fff; 
    z-index: 100; 
    padding-top: 20px; 
    padding-bottom: 20px; 
  } */

.FarClauseHeading>h3 {
    font-weight: 500;
    font-size: 19px;
    color: #1A57F2;
}
.fixedHeader{
    position:fixed;
    height:130px;
    width:91%;
    background-color: white;
    z-index: 1;
    /* box-shadow: 5px 10px 15px lightgray; */
    
}
/* .arrowContainer {
    position: fixed;
    border-radius: 10px 0 0 10px;
    top: 55%;
    left: 96%;
    width: 39px;
    height: 70px;
    background-color: #1976D2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
}
.arrowContainerLeft{
    position: fixed;
    border-radius: 10px 0 0 10px;
    top: 55%;
    left: 78%;
    width: 39px;
    height: 70px;
    background-color: #1976D2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
} */

.arrowContainer, .arrowContainerLeft {
    position: fixed;
    border-radius: 10px 0 0 10px;
    top: 65%;
    transform: translateY(-50%); /* Center vertically */
    width: 39px;
    height: 70px;
    background-color: #1976D2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    transition: left 0.3s ease; /* Smooth transition for the toggle */
  }
  .arrowContainer {
    left: 97.5%; /* Positioned outside the Far-container on the right */
  }
  
  .arrowContainerLeft {
    left: calc(94.5% - 16%); /* Positioned at the edge of Far-container */
  }
  
.MainContent{
    display:flex;
    /* padding-top:50px; */
}
.sideFarDivMain {
    width: 16%;
    max-height: 500px; /* Set a maximum height */
    min-height:500px;
    background-color: #fff;
    position: fixed;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    left: 81%;
    z-index: 2;
    top: 20%;
    overflow-y: scroll;
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    /* border:3px solid red; */
    margin-top: 75px;
}

.sideFarDivMain::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.sideFarDivHeader {
    height: 50px;
    width: 15.8%;
    display: flex;
    position:fixed;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid lightgray;
    /* border:2px solid blue */
}

.FarClausePartTitles{
    width: 95%;
    height: 89%;
    /* border: 1px solid blue; */
}
.farCause {
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    /* margin-bottom: 5px;  */
    /* margin-left: 20px; */
}
.farCause11 {
    /* display: flex; */
    align-items: center;
    /* justify-content: flex-end; */
    /* margin-bottom: 5px;  */
    margin-left: 20px;
}
.section{
    margin-left: 100px;
    margin-top: 20px;
}
.pointer{
    cursor:pointer;
}
.checkbox {
    margin-right: 2px; 
}

.blueIcon {
    margin-right: 5px; 
    margin-left: 10px;
    color: #1976D2; 
    cursor: pointer;
}

.numberContainer {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    padding:5px;
    width:100%;
    margin-top: 50px;
    justify-content: start;
    flex-wrap: wrap;
    margin-bottom: 10px;
    cursor: pointer;
    /* height: 100%; */
 
}
.numberItem {
    width: 15%;
    height: 30px;
    background-color: #1976D2;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    margin-bottom: 5px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 05px;
    transition: background-color 0.3s;
}

.ChapterData{
    margin-bottom: 20px;
    height:40px;
    background-color: #1976D2;
    margin-left:10px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
 
/* .numberItem+.numberItem {
    margin-left: 5px;
    margin-bottom: 10px;
}
 
 
.numberItem {
    width: calc(25% - 5px);
} */
.DropdownList{
    width:17%;
    /* margin-left:15px */
    /* border:2px solid red; */
}
.numberItem:hover {
    background-color: #fff;
    color: #1A57F2; /* Change text color on hover */
}
.dropdownMenu {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    z-index: 999;
    width: 120px;
    display: none;

}

.rightDivPart:hover .dropdownMenu {
    display: block;
    /* Show the dropdown on hover */
}

.dropdownMenu>div {
    cursor: pointer;
    padding: 5px;
}

.dropdownMenu>div:hover {
    background-color: #f0f0f0;
}

.farCause-container {
    border: 1px solid #ddd; 
    width: 90%;
    height: 20%;
    padding: 5px; 
    background-color: #fff; 
    margin-bottom: 5px; 
}  
.FarcontentDiv {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.FarcontentDiv h2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.FarcontentDiv p {
    font-size: 15px;
    margin-bottom: 10px;
}
.subpartTitle {
    color: #007bff; 
    text-decoration: underline; 
    cursor: pointer;
}

.subpartSection {
    color: #007bff; 
    text-decoration: underline; 
    cursor: pointer;
}



.FarcontentDiv button {
    position: absolute;
    top: 20px;
    /* right:30px; */
     left: 1050px; 
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.FarcontentDiv button:hover {
    background-color: #0056b3;
}

.sideFarDiv {
    transition: transform 0.3s ease;
}

.hidden {
    transform: translateX(100%);
}

.visible {
    transform: translateX(0);
}
.dialogTitle {
    display:flex;
    align-items: center;
    justify-content: left;
    width:93%;
    color: #1976D2;
  }
  
  .dialogContent {
    width:80%;
    display:flex;
    justify-content: space-between;
    margin:auto;
  }
  .buttonContent{
    width:80%;
    margin: auto;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
  }
  .dialogClose{
    color:red;
    /* padding-right:10px; */
  }
  
  .dialogPaper {
    border-radius: 10px;
    width:348px;
    height:155px;
    display:flex;
    flex-direction:row;
    padding:5px;
  }
  .dialogClause {
    border-radius: 10px;
    width: 348px;
    min-height: 350px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    height: auto;
}
.inputContent {
    position: relative;
  }
  
  .customDateInput {
    position: relative;
  }
  
  .popoverContent {
    position: absolute;
    top: 100%;
    left: 0;
    width:100%;
    box-shadow:5px 10px 15px rgb(85, 84, 84);
    border-radius:10px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    display: none;
    z-index: 999; /* Ensure the popover content is above other elements */
  }
  
  .infoIconWrapper:hover .popoverContent {
    display: block;
  }
  .infoIconWrapper{
    margin-left:20px;
  }

  .dialogHeader{
    width:100%;
    display:flex;
    align-items: center;
    padding-right: 20px;
  }
  .checkboxContent{
    width:100%;
    display:flex;
    justify-content: space-between;
    /* margin-bottom: 5px; */
  }
  .infoText{
    margin-bottom:10px;
    color:green;
  }
  .checkboxContentdata{
    width:48%;
    display:flex;
    border:1px solid gray;
    border-radius:5px;
    align-items:center
  }


  
  .inputContent{
    margin-bottom:20px;
    width:100%;
    height:40px;
  }
 
  .customFileInput {
    display: flex;
    /* background-color: #007bff; Button background color */
    /* color: #fff; Button text color */
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width:100%;
    align-items:center;
    justify-content: space-between;
    border:1px solid lightgray;
  }
  .customFileInput {
    margin-right: 20px; /* Adjust as needed */
  }
  /* .customFileInput .MuiSvgIcon-root {
    margin-left: 100px;
  } */
  /* .customFileInput input[type="date"] + .MuiSvgIcon-root {
    margin-left: 100px; 
    border: 4px;
    display:none;
} */

.customDateInput {
    position: relative;
  }
  
  .customDateInput input[type="date"] {
    position: relative;
    /* z-index: -1; */
  }
  
  .placeholderText {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index:2;
    transform: translateY(-50%);
    color: rgb(68, 67, 67);
    background-color: white;
    pointer-events: none; /* Ensures the text doesn't interfere with input */
  }
  
 

.customDateInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    width: 100%;
  }
  
 
  
  /* .customFileInput:hover {
    background-color: #0056b3; 
  } */
  
  .icon {
    margin-right: 8px; /* Adjust icon spacing */
  }
  .dateContent{
    display: flex;
     margin-bottom:20px;
     width:50px;
     align-items: center;
  }
  .dateInfoIcon{
    display:flex;
    width:50px;
    align-items: center;
    margin-left:30px;
    color:#0056b3;
  }
  .inputData{
    width:100%;
    border:none;
    outline:none;
    /* display:none; */
  }

  .dialogButton{
    width:100px;
    height:40px;
  }


/* .subpartDropdownMenu{
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    z-index: 999;
    width: 120px;
    display: none;
} */
@media screen and (max-width: 1400px) {
    /* .arrowContainer, .arrowContainerLeft {
        left: calc(94%  - 16%);
      } */
      .sideFarDivMain{
        width:23%;
        left:75%;
        top:23%;
    }
    .sideFarDivHeader{
        width:22%;
    }
      .arrowContainer {
          left: 97%; 
        }
      .arrowContainerLeft {
          left: 72%; 
        }
}
@media screen and (max-width: 1220px) {
    /* .arrowContainer, .arrowContainerLeft {
        left: calc(93.6%  - 16%); 
      } */
}
@media screen and (max-width: 1160px) {
    .DropdownList{
        width:16%;
    }
    /* .arrowContainer, .arrowContainerLeft {
        left: calc(93%  - 16%); 
      } */
}

@media screen and (max-width: 1110px) {
   
    .FarDivContainer{
        width:60%;
     }
    .clauseDate{
        width:40%;
        display:flex;
        flex-direction: column;
        justify-content: left;
    }
    .sideFarDivMain{
      width:38%;
      left:59%;
      top:23%;
  }
  .sideFarDivHeader{
      width:36%;
  }
    .arrowContainer {
        left: 96%; 
      }
    .arrowContainerLeft {
        left: 53%; 
      }
}

@media screen and (max-width: 1110px) {
   


.sideFarDivHeader{
    width:36%;
}
  .arrowContainer {
      left: 96%; 
    }
  .arrowContainerLeft {
      left: 56%; 
    }
}

@media screen and (max-width: 768px) {
 
     .mainFarTab{
        display:flex;
        flex-direction:column-reverse;
    }
    .Far-container {
      padding: 0 8px; /* Adjust padding for smaller screens */
    }
    .MainSearchBox{
        width:47%;
    }
    .DropdownList{
        width:47%;
    }
    /* .tabButton{
        display:none;
    } */
    .tabView{
        display:none;
    }
    .FarDivContainer{
        width:100%;
     }
    .clauseDate{
        /* display:none; */
        width:100%;
    }
    .sideFarDivMain{
        width:40%;
        left:55%;
        top:23%;
    }
    .sideFarDivHeader{
        width:38%;
    }
    .arrowContainer {
        left: 95%; 
      }
    .arrowContainerLeft{
      left:51%;
    }
   
  }

  @media screen and (max-width: 560px) {
   
    .sideFarDivMain{
      width:53%;
      left:42%;
      top:23%;
  }

    .sideFarDivHeader{
        width:48%;
    }
      .arrowContainer {
          left: 92%; 
        }
      .arrowContainerLeft {
          left: 35.2%; 
          /* top:13%; */
          top: 65%;
        }

        .searchIcon {
          width: 15%;
      }
      
      .searchInput {
        width: 85%;
        }
    }

  