/* FeatureModal.css */

.feature-modal {
    /* margin: 2px; */
  }
  
  .accordion {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .accordion-header {
    background-color: #f1f1f1;
    padding: 10px;
    cursor: pointer;
  }
  
  .accordion-content {
    padding: 10px;
  }
  
  .feature-item {
    margin-bottom: 5px;
  }
  
  button-Feature-container{
    margin-top: 20px;
  }
  
  .cancel-button {
    margin-right: 10px;
  }
  
  .save-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  

  /* FeatureModal.css */

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(61, 131, 250);
  background-color: white;
  border-radius: 5px;
  /* background-color: rgb(61, 131, 250); */
  /* color:white; */
}
.accordion-open-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:40px;
  padding:5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border: 1px solid rgb(61, 131, 250);
  background-color: #f1f1f1; */
  background-color: rgb(61, 131, 250); 
   color:white;
}

.arrow {
  font-size: 20px;
}

.arrow.up {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 5px 5px;
  border: 1px solid rgb(61, 131, 250);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.feature-container {
  display: flex;
  flex-wrap: wrap;
}

.feature-item {
  flex: 0 0 25%; /* Four items in a row */
  margin-bottom: 10px; /* Adjust as needed */
  color:rgb(61, 131, 250)
}

.selected input[type="checkbox"] {
  /* Styles for the checkbox when selected */
  background-color: blue;
}

.selected label {
  /* Styles for the label text when selected */
  color: blue;
}

.feature-item {
  flex: 0 0 25%; /* Four items in a row */
  margin-bottom: 10px; /* Adjust as needed */
}

.feature-label {
  color: black;
  display: flex;
  align-items: center;
}

.feature-label.checked {
  color: rgb(61, 131, 250);
}

.feature-checkbox {
  accent-color: rgb(61, 131, 250); 
  margin-right: 5px; /* Space between checkbox and label */
}

/* Fallback for older browsers */
.feature-checkbox:checked {
  background-color: rgb(61, 131, 250);
  border-color: rgb(61, 131, 250);
}

.dialog-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  height:580px;
}

.dialog-content::-webkit-scrollbar {
  display: none;
}

/* Additional styling for buttons, etc. can be added here */
