.react-flow__edges {
    z-index: 3 !important;
  }
  
  .react-flow__nodes {
    z-index: 2 !important;
  }

  .viewport{
    width: 100%;
    height: 56vh;
  }

  .addStatus{
    padding: 0;
    color: #1976d2;
    border-style: none;
  }