@media only screen and (max-width: 767px) {
  .card {
    width: 100%;
  }

  .downloadBtn {
    display: none !important;
  }
}

.downloadBtn {
  margin-bottom: 0 !important;
}
