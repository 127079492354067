.suggestion {
  min-width: 80% !important;
  max-width: 80% !important;
  position: absolute;
  margin-left: -60px;
  margin-top: 3px;
}

.suggestion-words {
  min-width: 21% !important;
    max-width: 21% !important;
    position: absolute;
    margin-left: 44px;
    margin-top: 3px;
}

.suggesion-hover {
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  border: 1px solid white;
  font-size: 14px;
  position: absolute;
  cursor: context-menu;
  margin-top: 4px;
  margin-left: -60px;
}

.span-bar {
  /* border-bottom: 2px solid blue; */
  /* transition: 0.5s; */
  transition: border-color 0.5s linear;

}

.span-bar:hover {
  border-bottom: 2px solid red;
  cursor: pointer;
}

.span-bar-words {
  border-bottom: 2px solid red;
  cursor: pointer;
}

.s-text p {
  margin: 0;
  padding: 4px 4px;
  transition: 0.5s;
}
.cursor-pointer{
  cursor: pointer;
}
.s-text p:nth-child(odd) {
  background-color:#F2A0A7;
}

.s-text p:nth-child(even) {
  background-color:#F2D5CE;
}

.s-text p:hover {
  cursor: pointer;
  transform: scale(1.02);
  border-radius: 3px;
}

/* Highlighters */

.select-highlight{
  background-color: #b1d5e5;
}

.disabledEffect {
  pointer-events: none;
  opacity: 0.7;
  background: #CCC;
}