/* organization profile */
.Container {
    display: flex;
  }
  
  .content {
    /* transition: transform 0.3s; */
    cursor: pointer;
    width: 85%;
  }
  .attachlogo{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    color: #1976D2;
    margin-right: 10px;
  }
  
  .input-container {
    width: 100%;
    background-color: white;
    padding: 10px;
    /* height: 70px; */
    box-shadow: 2px 2px 3px -2px #000000;
    border-radius:15px;
    margin-bottom: 13px;
  }
  .file-upload-container {
    width: 100%;
    background-color: white;
    padding: 10px;
    height: 100px;
    box-shadow: 2px 2px 3px -2px #000000;
    border-radius:15px;
    margin-bottom: 13px;
  }
  
  .file-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .logo-name{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .attach-file-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    color: #1976d2;
  }
  
  .label-text {
    color: black;
    font-weight: bold;
    cursor: pointer;
  }
  .label-text-logo{
    font-size: 10px;
    color: black;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* .federalorgfield {
    width: 100%;
    box-shadow: 2px 2px 3px -2px #000000;
    margin-top: 12px;
  } */
  .image-card-container {
    margin-left: 20px;
    width:14%;
  }
  
  .form-leftside {
    width: 100%;
    background-color: white;
    padding: 5px;
    height: 70px;
    box-shadow: 2px 2px 3px -2px #000000;
  }
  
  /* OrgProfileMultiselect*/
  ::placeholder {
    font-weight: bold;
  }
  .invitebtn{
    margin-top: 20px;
  }
  .autocompleteContainer {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    max-height: 150px;

  }
  
  .clearButton {
    cursor: pointer;
    color: red;
  }
  
  .tag {
    background: #1976D2;
    color: white;
    padding: 2px;
    border-radius: 2px;
    margin: 2px;
    display: inline-block;
  }
  
  .closeIcon {
    cursor: pointer;
    color: white;
    margin-left: 10px;
    font-size: 15px;
  }

  .Buttonscontent{
    display: flex;
    column-gap: 7px;
  }
  