*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    /* background-color: #fff; */
}
 
h1{
    color: blue;
}
.input-feild{
    width: 450px;
    padding: 10px;
    margin: 5px;
    border-radius: 7px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    margin-left: 180px;
 
}
.firstParentDiv{
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
}
.right-container{
    border: 2px solid fuchsia;
    /* display: flex; */
    width: 90%;
    margin-left: 110px;
}
.button1{
    width: 150px;
    height: 30px;
    text-transform: none;
}
.Projects{
    width: 70px;
    height: 30px;
    margin-left: 180px;
}
.icons{
    width: 100px;
    height: 25px;
    display: flex;
    justify-content: space-around;
    /* margin-left: 180px; */
    /* margin-top: 20px; */
    border: 1px solid red;
}
.icons1{
    margin-top: -10px;
}
.parentdiv{
    display: flex;
    width: 67%;
    margin: auto;
    border: 1px solid yellowgreen;
    justify-content: space-between;
    align-items: center;
 
}
.icons-div{
    display: flex;
}
.parent-cards{
    display: flex;
    flex-wrap: wrap;
    border: 1px solid black;
    width: 67%;
    margin: auto;
   
}
.parent-cards .card {
    width: 160px;
    height: 180px;
    margin: 10px;
    border-radius: 10px;
}
.range-input{
    width: 100%;
    margin: 10px 0;
}
.range-input::-webkit-slider-thumb {
    background-color: red;
}
.range-input::-moz-range-thumb {
    background-color: red;
}
.parentcard2{
    width: 100%;
    height: 70px;
    line-height: 6px;
    border: 1px solid purple;
}
 
.card>p{
    color: red;
}
.lastcard{
    width: 100%;
    height: 50px;
    background-color: red;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
}
.lastcard>p{
    color: #fff;
    padding-top: 30px;
}
.lastcard>span{
    padding-top: 30px;
}
.lastcard2{
    width: 100%;
    height: 50px;
    background-color: yellow;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
}
.lastcard2>p{
    color: #fff;
    padding-top: 30px;
}
.lastcard2>span{
    padding-top: 30px;
}
.lastcard3{
    width: 100%;
    height: 50px;
    background-color: green;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
}
.lastcard3>p{
    color: #fff;
    padding-top: 30px;
}
.lastcard3>span{
    padding-top: 30px;
}
.folderA{
    border:0;
    border-bottom: 2px solid gray;
    width: 96%;
    height: 80%;
    display: block;
    /* display: flex;
    justify-content: center; */
    align-self: center;
}
.folderIcon{
    margin-left: 50px;
    margin-top: 30px;
}
.folderA>p{
    margin-left: 50px;
    /* margin-top: 30px; */
}
.folderA2{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    border: 1px solid crimson;
}
.custom-icon-size {
    font-size: 10px !important;
  }
 
  .left-section {
    display: flex;
    align-items: center;
  }

  .projectTitle{
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 15px;
    width:100%;
  }

.title_headTag{
    margin-top: 13px;
    color: rgb(61, 131, 250);
}
.title_arrowTag{
    color: rgb(61, 131, 250);
}

.commentModal{
        position: absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-color: white;
        padding: 20px;
        border-radius: 20px;
        outline: none;
        box-shadow:0px 4px 10px rgba(0, 0, 0, 0.1) ;
        width: 400px;
    
}

.member_info_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4)
}

.member_info_ptag{
    margin-top: 3px;
    margin-bottom: 0px;
    font-size: 14px;
}

.requestModal{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 20px;
border-radius: 10px;
outline: none;
box-shadow:0px 4px 10px rgba(0, 0, 0, 0.1) ;
width: 80%;
height: 80vh;
overflow: auto;

}

.selectAll_checkbox{
    display: flex;
    justify-content:end;
    align-items: center;
    margin: 5px 10px;
    padding: 0px 10px;

}
.selectAll_checkbox_label{
    margin-right: 5px;
}

.memberParent_div{
    height: 70%;
    overflow: auto;
}

.grant_access-div{
margin-top: 20px;
display: flex;
justify-content: end;
align-items: center;
}

.grantAccess_butoon_div{
    margin-right: 10px;
}

.Request_Access{
    color: #007BFF;
    margin-bottom: 0px;
}
#clause_ptag{
    margin: 5px 5px;
    color: blue;
    text-decoration: underline;
    cursor: pointer
}
#clause_ptag1{
    margin: 5px 5px;
    
}

#Clause-modal-title{
    color: #007BFF;
}

#closeIcon{
    color: red;
}
#filename_ptag{
    margin: 5px;
   
}
#clause_footer_div{
    margin-top: 10px;
    display: flex;
    justify-content: end;
}
#extractedClause_div{
    height: 70%;
    overflow: auto;
}

#clauseButton{
    font-size: 12px;
    margin-right:10px ;
    box-shadow:3px 3px 8px rgba(0, 0, 0, 0.2) ;
    text-transform: capitalize;
    border-radius: 10px;
    color: #007BFF;
    border-color: #007BFF;

}

#clauses_textfeild_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

#clauseButton{
    font-size: 12px;
    margin-right:10px ;
    box-shadow:3px 3px 8px rgba(0, 0, 0, 0.2) ;
    text-transform: capitalize;
    border-radius: 10px;
    color: #007BFF;
    padding-right: 12px ;

}
#addclauseButton {
    font-size: 12px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
    color: #1976D2;
    border-color: #1976D2;
    width: auto;
    margin-left: 10px;
}
#clauses_textfeild_main {
    width: calc(100% - 90px); 
}
#clauses_addButton_main {
    width: 90px; 
    display: flex;
    justify-content: center; 
    align-items: center;

}

.clauseProjectModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    box-shadow:0px 4px 10px rgba(0, 0, 0, 0.1) ;
    width: 70%;
    height: 80vh;
    overflow: auto;
}
.addProejct_input_div{
    margin-top: 8px;
}

.filname_Style{
    color: #1976D2;
}
#clauseProject_addButton_main{
    display: flex;
    justify-content: center; 
    align-items: center;
    margin-top: 10px;
}

.datepicker_Div{
    display: flex;
    text-align: center;
}
.datepicker_Div p{
    margin-top: 4px;
    margin-right: 5px;
}
.added-content {
    background-color: #3fff6c;
}

.removed-content {
    background-color: #ff7782;
}

@media only screen and (max-width: 600px) {

    .clauseProjectModal{
        width: 85%
      }

      .requestModal{
        width:90%
      }
}