.MuiPaper-root.MuiCard-root.makeStyles-root-299.MuiPaper-elevation1.MuiPaper-rounded {
    border: 0 !important;
  }
  

.MuiTabs-flexContainer{
    background-color: white !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
   .head {
       margin-top: 2% !important;
   }

   .headtwo {
       margin-left: -2% !important;
   }
  }