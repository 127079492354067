.trans-modal {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Remove overflow:auto to prevent the entire page from scrolling */
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .trans-open {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .trans-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-height: 80vh; /* Set maximum height to 80% of the viewport height */
    overflow: auto; /* Enable scrolling if content exceeds height */
    margin-left: 5%;
    border-radius: 10px;
  }
  
  .trans-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  .closeshr-btn img{
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    height: 25px
  }
  
  .closeshr-btn:hover,
  .closeshr-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .headerTranst{
    color:rgb(61, 131, 250);
  }
  
  .trans-close:hover,
  .trans-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .trans-table-container {
    max-height: 60vh; /* Adjust the maximum height of the table container */
    overflow-y: auto; /* Enable vertical scrolling for the table */
    overflow-x: auto; /* Enable horizontal scrolling for the table */
  }
  
  .trans-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .trans-table th,
  .trans-table td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
  
  .trans-table th {
    background-color: #007bff;;
    color: white;
  }
  
  
  
  
  h2 {
    text-align: center; /* Center align the text */
  }
  
  @media (max-width: 768px) {
    .trans-modal-content {
      width: 90%;
      margin-left: 15%;
    }
    
    .trans-table {
      font-size: 12px;
    }
    
    .trans-table th,
    .trans-table td {
      padding: 6px;
    }
  }
  
  .trans-button-container {
    text-align: right;
    margin-top: 20px;
  }
  
  .trans-update-button {
    padding: 10px 20px;
    background-color: #007bff;;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .trans-update-button:hover {
    background-color: #0056b3;
  }
  
  .headingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
 
  }