.editor {
    box-sizing: border-box;
    /* border: 1px solid #ddd; */
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    /* margin-bottom: 2em; */
    /* box-shadow: inset 0px 1px 8px -3px #ABABAB; */
    background: #fefefe;
    /* min-height: 240px; */
  }
  
  .editor .public-DraftEditor-content {
    min-height: 435px;
  }
  
  .editor .public-DraftEditorPlaceholder-root {
    color: gray;
    opacity: 0.4;
  }

  .dotone {
    height: 10px;
    width: 10px;
    background-color: orangered;
    border-radius: 50%;
    display: inline-block;
  }

  .dottwo {
    height: 10px;
    width: 10px;
    background-color: blueviolet;
    border-radius: 50%;
    display: inline-block;
  }

  .dotthree {
    height: 10px;
    width: 10px;
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
  }

  .MuiPopover-paper {
    background-color: white !important;
    height: 42%;
    width: 28%;
  }