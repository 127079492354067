/* AttachmentModal.css */

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
}

.header1 {
  display: flex;
  justify-content: space-between;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-header2 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: "15px";
  margin-bottom: 20px;
}

.section-creation-container {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.input-select-group {
  display: flex;
  gap: 10px;
  flex: 1;
}

.sector-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ai-select {
  flex: 2;
}

.create-section-button {
  margin-left: 15px;
  padding: 0.5rem 1rem;
}

.document-list-container {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.document-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.document-row.selected {
  background-color: lightblue;
}

.document-icon {
  margin-right: 5px;
}

.document-name {
  flex: 1;
}

.generate-section-button {
  display: block;
  margin: 0 auto;
  padding: 0.5rem 1rem;
}

.selectStyle {
  max-width: 80%;
}

.onsmallDiv {
  display: none;
}


@media only screen and (max-width: 768px) {
  .modal-header {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }

  .title {
    display: none;
    margin-bottom: 15px;
  }

  .modal-title {
    margin-bottom: 15px;
    border: "1px solid black"
  }

  .section-creation-container {
    width: 100%;
  }

  .modal-header2 {
    flex-direction: column;
  }

  .selectStyle {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .create-section-button {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    width: 100%;
    padding-left: 0px;
  }

  .onsmallDiv {
    display: contents;
  }


}


@media only screen and (max-width: 460px) {
  .smallDeviceStyle {
    width: 100%;    
  }

  .modal-title {
    padding-top: 20px;
  }

  .create-section-button {
    flex-direction: column-reverse;

  }
}