/* reodrer popup */

/* Popup.css */

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popup {
    background-color: white;
    color: black;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Optional: Add shadow for depth */
    margin-top: -100px;
  }
  
  .popup-content {
    text-align: center;
  }
  
  .popup button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }