.Maindiv{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 70px;
}
.headtag{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
 
}
/* .headtag_flex{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: auto;
    align-items: center;
    margin-top: 15px;
}
.headtag_inner_div{
    font-size: 15px;
    font-weight: 600;
} */
 
.headtag_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Enable wrapping for small screens */
    gap: 10px; /* Space between items */
    padding: 10px;
  }
  
  .headtag_inner_div {
    flex: 1; /* Make text occupy equal space */
    text-align: center;
    font-size: 16px;
  }

  .slider_div{
    width: 50%;
  }
.top_button_style{
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;
}
.top_button_style Button{
    border: 1px solid gray;
    width: 150px;
    cursor: pointer;
    margin-bottom: 20px;
}
 .editorDiv{
    margin-top:"40px";
    width: 95%;
    border-radius: "8px 8px 0px 0px";
    background-color: "white";
  
    height: 350px;
    overflow: scroll;
    box-shadow:"0 0px 0px rgba(0, 0, 0, 0.1" ;
}
 
div .bottomButtons{
    display: flex;
    justify-content: "space-between";
    gap: 5px;
    width: 95%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
    background: "white";
    min-height: 70px;
    max-height: 70px;
}


@media only screen and (max-width: 770px){
    .headtag{
        font-size: 15px;
    }

    .headtag_inner_div{
        font-size: 13px;
        margin : 0px 5px;
    }

    .headtag_flex{
        
        margin-top: 5px;
    }

}
 

.dark-mode .editorDiv{
    margin-top:"40px";
    width: 95%;
    border-radius: "8px 8px 0px 0px";
    background-color: "white";
    color: white;
    height: 350px;
    overflow: scroll;
    box-shadow:"0 0px 0px rgba(0, 0, 0, 0.1" ;
    border: 1px solid white ;
}