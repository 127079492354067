@media only screen and (max-width: 767px) {
  .send {
    width: 50% !important;
    margin-left: 25% !important;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
  }

  .discard {
    width: 50% !important;
    margin-left: 25% !important;
  }
  .pre{
    width: 105% !important;
    height:100vh !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .send {
    width: 120% !important;
  }

  .discard {
    width: 140% !important;
  }
}
