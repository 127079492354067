*,
*::before,
*::after {
  box-sizing: border-box;
}
.textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  border: 2px solid #74637f;
  border-radius: 0;
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
}
.text-container,
.backdrop,
.textarea {
  width: 100%;
  height: 180px;
}
.text-container {
  display: block;
  margin: 0 auto;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
}
/* mark {
    border-radius: 3px;
    color: transparent;
    background-color: #b1d5e5;
} */
.backdrop {
  position: absolute;
  z-index: 1;
  border: 2px solid #685972;
  background-color: #fff;
  overflow: auto;
  pointer-events: none;
  transition: transform 1s;
}
.highlights,
.textarea {
  padding: 10px;
  font: 20px/28px "Open Sans", sans-serif;
  letter-spacing: 1px;
}
.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}
