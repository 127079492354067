.modal-overlayR {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  /* Ensure the modal appears on top of other components */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background to prevent interaction with the page */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contentR {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  width: 50%;
  /* Adjust the width as needed */
}

.item-listR {
  list-style: none;
  padding: 0;
  max-height: 300px;
  /* Limit the height of the list to fit in the modal */
  overflow-y: auto;
  text-align: left;
}

.list-itemR {
  padding: 10px;
  cursor: pointer;
  border: 1px solid lightgray;
  background-color: rgb(240, 240, 240);
}

.list-itemR.selected {
  background-color: skyblue;
}

.button-groupR {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.save-buttonR {
  margin-left: 10px;
  padding: 10px;
  background-color: blue;
  color: white;
  border-radius: 10px;
  border: none;
}

.cancel-buttonR {
  padding: 10px;
  background-color: white;
  color: red;
  border-radius: 10px;
  border: 1px solid red;
}

@media (max-width: 1024px) {
  .modal-contentR {
    width: 70%; 
  }
}

@media (max-width: 600px) {
  .modal-contentR {
    width: 90%; 
  }
}