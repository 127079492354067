@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #payLabel {
    font-size: 10px;
  }

  #appbar {
    width: 50vw !important;
    height: 100vh !important;
  }

  .jumbotron {
    height: 150vh !important;
  }

  #jumboc {
    height: 140vh !important;
  }

  .maincont {
    padding-right: 4% !important;
  }

  .flex-container {
    display: flex;
    justify-content: center;
  }

  .flex-container > div {
    text-align: center;
  }

  .payCont {
    width: 107% !important;
    margin-left: -4% !important;
  }

  /* .MuiTabs-scroller {
    width: 50% !important;
  } */
}

/* .MuiTabs-flexContainer {
  background-color: white !important;
} */

.flex-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.flex-container > div {
  /* width: 100px; */
  /* margin: 10px; */
  text-align: center;
  /* line-height: 75px; */
}
