.form-container{
    margin-bottom: 20px;
    background-color: white;
    padding-top: 10px;
    transition: border 0.3s ease;
    padding-right: 10px;
    padding-left: 10px;
    width:100%;
    height:82px;
    border-radius: 10px;
}
.form-container :hover{
    border-color: blue;
}

.custom-date-picker {
  width: 100%;
}

.custom-date-picker .ant-picker-input input {
  height: 45px;
  padding: 0 10px;
  border-radius: 4px;
  /* border: 1px solid #ccc; */
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); */
  /* transition: box-shadow 0.3s; */
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.custom-date-picker {
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.custom-date-picker .ant-menu-item {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  font-size: 14px;
}

.custom-date-picker .ant-menu-item:hover {
  background-color: #92c7cf;
}

.custom-date-picker .ant-form-item-label {
  color: black;
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  font-size: 0.9rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
}

.custom-date-picker .ant-form-item-label.Mui-focused {
  color: #6e6e6e;
  position: absolute;
  left: 0;
  top: 0;
}
